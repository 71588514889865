import React, {
  Suspense,
  lazy,
  useState,
  createContext,
  useEffect,
  useRef,
} from "react";
import logo from "./logo.png";
import tableuserDefaultImg from "./assets/avatar.png";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import AppLogout from "./admin/AppLogout";
import Login from "./admin/Login";

import Layout from "./components/admin/layout/Layout";
import Dashboard from "./admin/dashboard/Dashboard";

import Users from "./admin/users/Users";
import Useredit from "./admin/users/Useredit";
import Useradd from "./admin/users/Useradd";
import Userview from "./admin/users/Userview";

import Order from "./admin/orders/Order";
import Orderview from "./admin/orders/Orderview";

import Staff from "./admin/staff/Staff";
import Staffedit from "./admin/staff/Staffedit";
import Staffadd from "./admin/staff/Staffadd";

import Origins from "./admin/origin/Origins";
import Originedit from "./admin/origin/Originedit";
import Originadd from "./admin/origin/Originadd";

import Settings from "./admin/Settings";
import Profile from "./admin/Profile";
import Notfound from "./admin/Notfound";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Redirect,
  Route,
  Link,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import Webroute from "./Webroute";
import packageJson from "../package.json";

import { changeDateFotmate, currencySymbol } from "./functions/Function";

const WebAssetsContext = createContext();

function App() {
  let getuserloggedIn = JSON.parse(localStorage.getItem("admin"));
  const [userloggedIn, setUserloggedIn] = useState(getuserloggedIn);
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("admin");
    setUserloggedIn(null);
  };

  const logout1 = () => {
    localStorage.removeItem("admin");
    setUserloggedIn(null);
  };

  const LoggedInAdmin = () => {
    let getuserloggedIn = JSON.parse(localStorage.getItem("admin"));
    if (getuserloggedIn) {
      setUserloggedIn(getuserloggedIn);
    } else {
      setUserloggedIn(null);
    }
  };
  const [AdminAssets, setAdminAssets] = useState({
    //apiURL: 'https://projects.softechplanet.com/clientcrm/api/' ,
    apiURL: "https://po.stickerpack.com/server/api/",
   // apiURL: "https://oms.softechplanet.com/api/",
    // apiURL: "https://p.softechplanet.com/oms/api/",
    logo: logo,
    tableuserDefaultImg: tableuserDefaultImg,
    LoggedInAdmin: LoggedInAdmin,
    logout: logout,
  });

  const Protected = ({ children }) => {
    if (userloggedIn == null) {
      return <Navigate to="/admin/login" replace />;
    }
    return children;
  };

  useEffect(() => {
    LoggedInAdmin();

    // if(packageJson.version != localStorage.getItem("admin"))
    // {
    //   logout1();
    // }
    // console.log(packageJson.version);
    // console.log(localStorage.getItem("admin"));
  }, []);

  return (
    <>
      <WebAssetsContext.Provider value={AdminAssets}>
        <AppLogout logout={logout1}>
          <Router basename="/">
            <Webroute />
            <Routes >
              <>
                <Route
                  path="/admin"
                  element={
                    <Protected>
                      <Navigate replace to={"/admin/dashboard"} /> 
                    </Protected>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <Protected>
                      <Layout />
                    </Protected>
                  }
                >
                  <Route
                    index
                    element={
                      <Protected>
                        <Navigate replace to={"/admin/dashboard"} />
                      </Protected>
                    }
                  />
                  <Route
                    path="/admin/dashboard"
                    element={
                      <Protected>
                        <Dashboard
                          changeDateFotmate={changeDateFotmate}
                          currencySymbol={currencySymbol}
                        />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/clients"
                    element={
                      <Protected>
                        <Users
                          changeDateFotmate={changeDateFotmate}
                          currencySymbol={currencySymbol}
                        />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/orders"
                    element={
                      <Protected>
                        <Order
                          changeDateFotmate={changeDateFotmate}
                          currencySymbol={currencySymbol}
                        />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/orders/order/:id"
                    element={
                      <Protected>
                        <Orderview />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/staff"
                    element={
                      <Protected>
                        <Staff
                          changeDateFotmate={changeDateFotmate}
                          currencySymbol={currencySymbol}
                        />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/staff/add"
                    element={
                      <Protected>
                        <Staffadd />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/staff/edit/:id"
                    element={
                      <Protected>
                        <Staffedit />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/origins"
                    element={
                      <Protected>
                        <Origins
                          changeDateFotmate={changeDateFotmate}
                          currencySymbol={currencySymbol}
                        />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/origin/add"
                    element={
                      <Protected>
                        <Originadd />
                      </Protected>
                    }
                  />{" "}
                  <Route
                    exact
                    path="/admin/origin/edit/:id"
                    element={
                      <Protected>
                        <Originedit />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/settings"
                    element={
                      <Protected>
                        <Settings />
                      </Protected>
                    }
                  />
                  <Route
                    exact
                    path="/admin/profile"
                    element={
                      <Protected>
                        <Profile />
                      </Protected>
                    }
                  />
                  <Route path="*" element={<Notfound />} />
                </Route>
              </>

              <Route
                exact
                path="/admin/login"
                element={
                  userloggedIn == null ? (
                    <Login />
                  ) : (
                    <Navigate replace to={"/admin/dashboard"} />
                  )
                }
              />
            </Routes>
          </Router>
        </AppLogout>
      </WebAssetsContext.Provider>
    </>
  );
}

export default App;
export { WebAssetsContext };
