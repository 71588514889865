import React ,   {     useState , useContext , useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import '../../.././assets/css/app.min.css';
//import '../../.././assets/css/style.css';
//import '../../.././assets/css/components.css';
//import '../../.././assets/css/custom.css';
import avatar from '../../.././assets/img/user.png';
import  { WebAssetsContext }  from "../../../App";
import  { loggedInUserDetails , filebasepath }  from "../../../functions/Function";

import { Link , NavLink  } from "react-router-dom";

const  Header = (props) => {
   

  const navigate = useNavigate();
  const WebAssets = useContext(WebAssetsContext);
  const [MyAccounttoggle, setMyAccounttoggle] = useState(false);
  
  const onclickToggle = (url='') => 
  {
    setMyAccounttoggle(false);
    navigate(url);
  }

  return (
    <>
        <div className="navbar-bg"></div>
          <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
             
              <button onClick={() => props.minibatToggle() } className="btn btn-sm btn-primary"> <i className="fas fa-bars"></i></button>
              
              </li>
              <li>
                <Link  className="nav-link nav-link-lg fullscreen-btn">
                <i data-feather="maximize"></i>
              </Link>
              </li>
            
          </ul>
        </div>
        <ul className="navbar-nav navbar-right">
         
         
          <li className={ MyAccounttoggle == true ? 'dropdown show' : 'dropdown' }>
            
            <Link  onClick={() => setMyAccounttoggle(!MyAccounttoggle)} data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"> 
              {loggedInUserDetails('profile') != '' ? 
                 <img alt="image" src={filebasepath() + loggedInUserDetails('profile')}
                 className="user-img-radious-style" />
              : 
                <img alt="image" src={WebAssets.tableuserDefaultImg}
                className="user-img-radious-style" />
              }
              <span className="d-sm-none d-lg-inline-block"></span></Link>
            <div className={ MyAccounttoggle == true ? 'dropdown-menu dropdown-menu-right pullDown show' : 'dropdown-menu dropdown-menu-right pullDown' }>
              <div className="dropdown-title">Hello {loggedInUserDetails('full_name')} </div>

              <button  onClick={() =>  onclickToggle('/admin/profile')}  className="dropdownmyaccountbtn btn-link  dropdown-item has-icon"> <i className="far
										fa-user"></i> Profile
              </button>
              
                <button  onClick={() =>  onclickToggle('/admin/settings')}   className="dropdownmyaccountbtn btn-link dropdown-item has-icon"> <i className="fas fa-cog"></i>
                  Settings
              </button>
              <div className="dropdown-divider"></div>
              <Link  onClick={WebAssets.logout} className="dropdown-item has-icon text-danger"> <i className="fas fa-sign-out-alt"></i>
                Logout
              </Link>
            </div>
          </li>
        </ul>
        </nav>
    </>

  );
}

export default Header;
