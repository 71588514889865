import React, { useState, useEffect } from "react";
const Designitem = (props) => {
  const [keyword, setkeyword] = useState("");
  const [state, setState] = useState(props.currentValue);

  const calculteForLargeSizePrice = (qty) => {
    let price = 0.0;
    if (qty >= 25 && qty <= 49) {
      price = 1.75 * qty;
    } else if (qty >= 50 && qty <= 99) {
      price = 1.5 * qty;
    } else if (qty >= 100 && qty <= 249) {
      price = 1.25 * qty;
    } else if (qty >= 250 && qty <= 499) {
      price = 1.15 * qty;
    } else if (qty >= 500) {
      price = 1.0 * qty;
    }
    return price.toFixed(2);
  };

  const calculteForSmallSizePrice = (qty) => {
    let price = 0.0;
    if (qty >= 25 && qty <= 49) {
      price = 1.12 * qty;
    } else if (qty >= 50 && qty <= 99) {
      price = 1.0 * qty;
    } else if (qty >= 100 && qty <= 249) {
      price = 0.8 * qty;
    } else if (qty >= 250 && qty <= 499) {
      price = 0.75 * qty;
    } else if (qty >= 500) {
      price = 0.65 * qty;
    }
    return price.toFixed(2);
  };

  const calcultePrice = (getqty, getsize) => {
    let output;
    if (getsize == "Small") {
      output = calculteForSmallSizePrice(getqty);
    }

    if (getsize == "Large") {
      output = calculteForLargeSizePrice(getqty);
    }

    return output;
  };

  const handleChangeqty = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState(value);

    let dispplaynew = props.inputs.display;
    dispplaynew[props.index].qty = value;

    dispplaynew[props.index].price = calcultePrice(
      value,
      dispplaynew[props.index].size
    );
    props.setInputs((values) => ({ ...values, display: dispplaynew }));
  };

  const handleChangeNumber = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState(value);
    let dispplaynew = props.inputs.display;
    dispplaynew[props.index].design_number = value;
    props.setInputs((values) => ({ ...values, display: dispplaynew }));
  };

  const handleChangeCustom = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState(value);
    let dispplaynew = props.inputs.display;
    dispplaynew[props.index].customization = value;
    props.setInputs((values) => ({ ...values, display: dispplaynew }));
  };

  const handleChangeSize = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState(value);
    let dispplaynew = props.inputs.display;
    dispplaynew[props.index].size = value;
    dispplaynew[props.index].price = calcultePrice(
      dispplaynew[props.index].qty,
      value
    );
    props.setInputs((values) => ({ ...values, display: dispplaynew }));
  };

  const handleUpdateKeyword = (event) => {
    const value = event.target.value;
    let cat = props.inputs.category;
    cat[props.index].keyword = value;
    props.setInputs((values) => ({ ...values, category: cat }));
  };

  const generateOptions = () => {
    const options = [];
    options.push(<option value={0}>-</option>);
    for (let i = 25; i <= 2500; i += 25) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  useEffect(() => {
    setState(props.currentValue);
    // props.setInputs((values) => ({ ...values, display: dispplaynew }));
  }, [props.currentValue]);



  const initializeDisplay = () => {
    let dispplaynew = [...props.inputs.display]; // Clone display array
    dispplaynew[props.index].size = "Small"; // Set size to "Small"
    dispplaynew[props.index].qty = 25; // Set qty to 25
    dispplaynew[props.index].price = calcultePrice(25, "Small"); // Calculate price
    props.setInputs((values) => ({ ...values, display: dispplaynew }));
  };

  useEffect(() => {
    if (props.inputs && props.inputs.display && props.inputs.display.length > 0) {
      initializeDisplay(); // Initialize size and qty for the first time
    }
  }, []);

  return (
    <div className="col-lg-12 my-keryword-form-list-colum">
      <div className="row">
        <div className="col-1">{props.index + 1}</div>
        <div className="col-6 col-sm-3 col-md-2 col-lg-3 my-keryword-form-list-box">
          <div className="form-floating myform-floating">
            <select
              className="form-select form-control"
              id="floatingSelectGrid"
              aria-label="Floating label select example"
              defaultValue="Size"
              name="size"
              value={props.data.size}
              onChange={handleChangeSize}
            >
              <option selected value="">
                Size
              </option>
              <option value="Large">Large</option>
              <option value="Small">Small</option>
            </select>
          </div>
        </div>
        <div className="col-6 col-sm-2 col-md-2 col-lg-3 my-keryword-form-list-box">
          {/* <input 
                  type="number"
                  className="form-control"
                  placeholder="Quantity" 
                  name="qty"
                  value={props.data.qty} 
                  onChange={handleChangeqty}
                /> */}

          <select
            className="form-select form-control"
            aria-label="Floating label select example"
            defaultValue="50"
            name="size"
            value={props.data.qty}
            onChange={handleChangeqty}
          >
            {generateOptions()}
          </select>
        </div>
        <div className="col-6 col-sm-2 col-md-3 col-lg-2 my-keryword-form-list-box">
          <input
            type="text"
            className="form-control"
            placeholder="Design Number"
            name="design_number"
            value={props.data.design_number}
            onChange={handleChangeNumber}
          />
        </div>
        <div className="col-6 col-sm-5 col-md-5 col-lg-3 my-keryword-form-list-box">
          <input
            type="text"
            className="form-control"
            placeholder="Customization"
            name="customization"
            value={props.data.customization}
            onChange={handleChangeCustom}
          />
        </div>
      </div>
    </div>
  );
};

export default Designitem;
