import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { WebAssetsContext } from "../../App";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import {
  updateSession,
  loaderBar,
  filebasepath,
  apibaseURl,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
  getvalidationErr,
  changeDateFotmate,
  miniloaderBar,
} from "../../functions/Function";

const Exchangesalesperson = (props) => {
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setselectedUser] = useState(props.currentOrderId);

  const handlechange = (e) => {
    setselectedUser(e.target.value);
  };

  const handleUpdate = async () => {
    setDisabled(true);
    try {
      let res = await fetch(apibaseURl + "exchangeOrderSalesPerson", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: selectedUser,
          order_id: props.currentOrderId,
        }),
      });
      let output = await res.json();
      if (res.status === 200) {
        setDisabled(false);
        if (output.status == true) {
          successToast(output.response, "top-right", "6000");
        } else {
          if (output.validation == true) {
            errorToast(getvalidationErr(output.response), "top-right", "6000");
          } else {
            errorToast(output.response, "top-right", "6000");
          }
        }
      }
    } catch (err) {
      setDisabled(false);
    }
  };

  const fetchData = () => {
    fetch(apibaseURl + "getstaffs")
      .then((res) => res.json())
      .then((result) => {
        if (result.status == true) {
          setUsers(result.response);
        }
      });
  };
  useEffect(() => {
    //getOrderDetails();
  }, []);
  return (
    <>
      <ToastContainer />
      <Modal
        show={props.show}
        onHide={props.handleClose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Exchange Sales Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="d-block">Select Sales Person</label>
            <select
              className="form-control"
              defaultValue={props.currentsalesperson}
              onClick={handlechange}
            >
              <option value={""}>Select Sales Person</option>
              {props.salesPerson.length > 0 &&
                props.salesPerson.map((row, i) => (
                  <option value={row.id}>{row.full_name}</option>
                ))}
            </select>
            <div className="mt-3 text-center">
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.handleClose}>
            Close
          </Button>
          {/* Add any other buttons or actions you need */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Exchangesalesperson;
