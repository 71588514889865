import React, {
  Suspense,
  lazy,
  useState,
  useContext,
  useEffect,
  browserHistory,
} from "react";
import { useNavigate } from "react-router-dom";
import { WebAssetsContext } from "../App";
import Swal from "sweetalert2";
import { ToastContainer, toast, Zoom } from "react-toastify";
import packageJson from "../../package.json";
import {
  alertDanger,
  apibaseURl,
  errorToast,
  successToast,
  getvalidationErr,
} from "../functions/Function";
const Admincss = React.lazy(() => import("../Admincss"));
const Login = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const errortoast = (text) => {
    toast.error(text, {
      autoClose: 3000,
      transition: Zoom,
      theme: "colored",
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const logginSuccess = () => {
    WebAssets.LoggedInAdmin();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      let res = await fetch(apibaseURl + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      let output = await res.json();
      setDisabled(false);
      if (res.status === 200) {
        if (output.status === 2) {
          getvalidationErr(output.response);
        }
        if (output.status === true) {
          setDisabled(false);

          Swal.fire({
            title: "Congratulations",
            text: "You logged in successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            if (result.isDismissed) {
              let user = {
                token: output.response.id,
                user: output.response,
              };
              localStorage.setItem("admin", JSON.stringify(user));
              localStorage.setItem(
                "verison",
                JSON.stringify(packageJson.version)
              );
              logginSuccess();
            }
          });
        }
      }
    } catch (err) {
      setDisabled(false);
    }
  };

  const getvalidationErr = (errors) => {
    const errorsConvertInArry = Object.values(errors);
    let collectAllValidationErr = [];
    errorsConvertInArry.forEach((error, index) => {
      collectAllValidationErr.push(<div key={index}>{error}</div>);
    });
    const storeAllValidtionErr = <>{collectAllValidationErr}</>;
    errortoast(storeAllValidtionErr);
  };
  return (
    <>
      <Suspense>
        <Admincss />
        <ToastContainer />
        <div id="app" className="authp authcustom">
          <section className="section w-100">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                  <div className="card card-primary">
                    <div className="text-center">
                      <img
                        alt="image"
                        src={WebAssets.logo}
                        className="w-20 pt-4"
                        width="100"
                      />
                    </div>
                    <div className="card-header d-block">
                      <h4 className="text-center">Login to Administrator</h4>
                    </div>

                    <div className="card-body">
                      <form method="POST" action="#" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            name="email"
                            placeholder="Email"
                          />
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            name="password"
                            placeholder="Password"
                          />
                        </div>

                        <div className="form-group">
                          <button
                            className="btn btn-primary mr-1"
                            type="submit"
                            disabled={disabled}
                          >
                            {" "}
                            {disabled == false ? "Login" : "Logining..."}{" "}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Suspense>
    </>
  );
};

export default Login;
