import './assets/web/css/bootstrap.min.css';
import './assets/web/css/style.css';
import './Web.css';
import './assets/web/css/responsive.css';
import 'animate.css';





const  Webassets = (props) => {
    return (
      <>
          
      </>
  
    );
  }
  
  export default Webassets;