import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { WebAssetsContext } from "../../App";
import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  alertDanger,
  apibaseURl,
  errorToast,
  successToast,
  getvalidationErr,
} from "../../functions/Function";
const Useredit = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  const params = useParams();
  const [disabled, setDisabled] = useState(false);
  const [id, setId] = useState(params.id);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("1");

  const successtoast = (text = "") => {
    toast.success(text);
  };

  const errortoast = (text) => {
    toast.error(text, {
      autoClose: 3000,
      transition: Zoom,
      theme: "colored",
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const actvieStatus = () => {
    setStatus("1");
  };

  const inactvieStatus = () => {
    setStatus("0");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      let res = await fetch(apibaseURl + "updateOrigin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          title: title,
          status: status,
        }),
      });
      let output = await res.json();
      setDisabled(false);
      if (res.status === 200) {
        if (output.status === 2) {
          getvalidationErr(output.response);
        }
        if (output.status === true) {
          Swal.fire({
            title: "Success",
            text: "Origin updated successfully!",
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/admin/origins");
            }
          });
        }
      }
    } catch (err) {
      setDisabled(false);
    }
  };

  const getvalidationErr = (errors) => {
    const errorsConvertInArry = Object.values(errors);
    let collectAllValidationErr = [];
    errorsConvertInArry.forEach((error, index) => {
      collectAllValidationErr.push(<div key={index}>{error}</div>);
    });
    const storeAllValidtionErr = <>{collectAllValidationErr}</>;
    errortoast(storeAllValidtionErr);
  };

  const getuserdata = async () => {
    const res = await fetch(apibaseURl + "getOrigin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    });
    let resJson = await res.json();
    return resJson;
  };

  const getSingleRecord = async () => {
    try {
      const resJson = getuserdata();
      resJson.then(function (output) {
        if (output.status === true) {
          console.log(output.response);
          setTitle(output.response.title);
          setStatus(output.response.status);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSingleRecord();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>Edit Origin Information</h4>
              <div className="card-header-form">
                <button
                  onClick={() => navigate("/admin/origins")}
                  className="btn btn-icon icon-left btn-primary"
                >
                  <i className="fas fa-arrow-right"></i> Back
                </button>
              </div>
            </div>
            <div className="card-body p-4">
              {title ? (
                <form onSubmit={handleSubmit} id="create-course-form">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>
                          Title <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          value={title}
                          placeholder="Title"
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Status</label>
                        <div className="row">
                          <div className="col-sm-3">
                            <input
                              type="radio"
                              id="statusActive"
                              name="status"
                              className=""
                              value="1"
                              checked={status === "1"}
                              onChange={actvieStatus}
                            />{" "}
                            <label htmlFor="statusActive">Active</label>
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="radio"
                              id="statusInactive"
                              name="status"
                              className="custom-control-inpu"
                              value="0"
                              checked={status === "0"}
                              onChange={inactvieStatus}
                            />{" "}
                            <label htmlFor="statusInactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="card-footer text-center">
                        <button
                          className="btn btn-primary mr-1"
                          type="submit"
                          disabled={disabled}
                        >
                          {" "}
                          {disabled == false ? "Submit" : "Submiting..."}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Useredit;
