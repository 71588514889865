import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Matte from "../../assets/web/images/Matte.png";
import Glossy from "../../assets/web/images/Glossy.png";
import HangTagged from "../../assets/web/images/Hang Tagged.png";
import USA from "../../assets/web/images/Made in the USA.png";
import Barcoded from "../../assets/web/images/Barcoded.png";
import PrePriced from "../../assets/web/images/Pre Priced.png";
import SidedMetalFloor1 from "../../assets/web/images/4 Sided Metal Floor - HT.jpg";
import SidedMetalFloor2 from "../../assets/web/images/4 Sided Metal Counter - HT.jpg";
import SidedMetalFloor3 from "../../assets/web/images/4 Sided Floor - Light.jpg";
import SidedMetalFloor4 from "../../assets/web/images/2 Sided Floor - Light.jpg";
import SidedMetalFloor5 from "../../assets/web/images/4 Sided Counter - Light.jpg";
import SidedMetalFloor6 from "../../assets/web/images/4 Sided Floor 2 Floor - Light.jpg";
import useDownloader from "react-use-downloader";

import { WebAssetsContext } from "../../App";
import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  updateSession,
  loaderBar,
  filebasepath,
  apibaseURl,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
  getvalidationErr,
  changeDateFotmate,
  miniloaderBar,
} from "../../functions/Function";

const Orderview = (props) => {
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState({});
  const getOrderDetails = async () => {
    let response = await fetch(apibaseURl + "order/" + params.id);
    if (response.status === 200) {
      let result = await response.json();
      if (result.result == true) {
        setData(result.response);
      }
    }
  };

  const grandTotal = (amount = 0, discount = 0) => {
    const totalAmount = Number(amount);
    const totalDiscount = Number(discount);
    const result = (totalAmount - totalDiscount).toFixed(2);
    return result;
  };

  useEffect(() => {
    getOrderDetails();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>Order Information</h4>
              <div className="card-header-form">
                <button
                  onClick={() => navigate("/admin/orders")}
                  className="btn btn-icon icon-left btn-primary"
                >
                  <i className="fas fa-arrow-right"></i> Back
                </button>
              </div>
            </div>
            <div className="card-body p-4">
              {Object.keys(data).length == 0 && miniloaderBar(100, 300)}
              {Object.keys(data).length > 0 && (
                <div className="product-detail-box row">
                  <table className="table table-striped product-first-list">
                    <tbody>
                      <tr>
                        <th scope="row">Order Origin</th>
                        <td>{data.order_origin}</td>
                      </tr>

                      <tr>
                        <th scope="row">Reorder</th>
                        <td>{data.reorder}</td>
                      </tr>

                      <tr>
                        <th scope="row">Company Name</th>
                        <td>{data.company_name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Buyer Email</th>
                        <td>{data.buyer_email}</td>
                      </tr>
                      <tr>
                        <th scope="row">Buyer Phone Number</th>
                        <td>{data.buyer_phone}</td>
                      </tr>
                      <tr>
                        <th scope="row">Delivery Date</th>
                        <td>
                          {data.delivery_date != "0000-00-00" &&
                            changeDateFotmate(
                              data.delivery_date,
                              "DD-MM-YYYY"
                            )}{" "}
                          {data.delivery_type == "asready" && "As Ready"}{" "}
                          {data.delivery_type == "asap" && "ASAP - RUSH"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Terms</th>
                        <td>{data.terms}</td>
                      </tr>
                      <tr>
                        <th scope="row">Shipping Type</th>
                        <td>{data.shipping_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Shipping Address</th>
                        <td>{data.usps_shipping_address}</td>
                      </tr>
                      {data.fedex_shipping_address !== null && (
                        <tr>
                          <th scope="row">Fedex Shipping Address</th>
                          <td>{data.fedex_shipping_address}</td>
                        </tr>
                      )}
                      <tr>
                        <th scope="row">
                          <h5 className="detail-box-inner-heading">FINISH</h5>
                        </th>
                        <td>
                          <span className="finish-box">
                            {data.finish == "Matte" && (
                              <img className="img-fluid" src={Matte} alt="" />
                            )}
                            {data.finish == "Glossy" && (
                              <img className="img-fluid" src={Glossy} alt="" />
                            )}
                          </span>{" "}
                          {data.finish}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <h5 className="detail-box-inner-heading">HANGTAG</h5>
                          <span className="finish-box">
                            <img
                              className="img-fluid"
                              src={HangTagged}
                              alt=""
                            />
                          </span>
                        </th>
                        <td>
                          <div className="detail-check">
                            <h6>{data.hangtag}</h6>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <h5 className="detail-box-inner-heading">
                            MADE IN THE USA
                          </h5>
                          <span className="finish-box">
                            {" "}
                            <img className="img-fluid" src={USA} alt="" />
                          </span>
                        </th>
                        <td>
                          <div className="detail-check">
                            <h6>{data.madeinusa}</h6>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <h5 className="detail-box-inner-heading">BARCODE</h5>
                          <span className="finish-box">
                            <img className="img-fluid" src={Barcoded} alt="" />
                          </span>
                        </th>
                        <td>
                          <div className="detail-check">
                            <h6>{data.barcode}</h6>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <h5 className="detail-box-inner-heading">
                            PRE PRICED
                          </h5>
                          <span className="finish-box">
                            {" "}
                            <img className="img-fluid" src={PrePriced} alt="" />
                          </span>
                        </th>
                        <td>
                          <div className="detail-check">
                            <h6>{data.prepriced}</h6>
                            {data.prepriced == "Yes" && (
                              <p className="mt-1">
                                {currencySymbol}
                                {data.prepriceamt}
                              </p>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"> Requested Name Drop</th>
                        <td> {data.requested_name_drop}</td>
                      </tr>

                      <tr>
                        <th scope="row"> Other</th>
                        <td> {data.other}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <h5 className="detail-box-inner-heading">
                            ORDER TYPE
                          </h5>
                        </th>
                        <td>
                          <h6>
                            {data.displaytype == "display_by_keyword" &&
                              "Display By Keyword"}
                            {data.displaytype == "display_plus_design" &&
                              "Display Plus Design"}
                            {data.displaytype == "design_only" && "Design Only"}
                          </h6>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">
                          <div className="display_top_heading_div">
                            <h5 className="detail-box-inner-heading top_display_type">
                              DISPLAY TYPES{" "}
                            </h5>
                          </div>
                        </th>
                        <td>
                          {data.displaytypedata.length > 0 &&
                            data.displaytypedata.map((row, i) => (
                              <>
                                {console.log(row)}
                                <p className="para_line_height_orders">
                                  {row.label}
                                  <b>(Qty:{row.qty}) </b>
                                </p>
                              </>
                            ))}
                        </td>
                      </tr>

                      {data.displaytype == "display_by_keyword" && (
                        <tr>
                          <th scope="row">Category Likes</th>
                          <td>
                            {data.display.length > 0
                              ? data.display.map((row, i) => (
                                  <p>
                                    {" "}
                                    {row.keyword} <b> ({row.percentage}%)</b>
                                  </p>
                                ))
                              : ""}
                          </td>
                        </tr>
                      )}

                      {(data.displaytype == "display_plus_design" ||
                        data.displaytype == "design_only") && (
                        <tr>
                          <td colSpan={2}>
                            <table className="table my-table table-striped product-first-list">
                              <thead>
                                <tr>
                                  <th>Size</th>
                                  <th>Quantity</th>
                                  <th>Design Number</th>
                                  <th>Customization</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.category_keywords.length > 0
                                  ? data.category_keywords.map((row, i) => (
                                      <tr key={i}>
                                        <td data-label="Size"> {row.size}</td>
                                        <td data-label="Quantity">{row.qty}</td>
                                        <td data-label="Design Number">
                                          {row.design_number}
                                        </td>
                                        <td data-label="Customization">
                                          {row.customization}
                                        </td>
                                      </tr>
                                    ))
                                  : ""}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}

                      <tr className="uploaded-images-detail finish-box">
                        <th scope="row">Images</th>
                        <td>
                          <div className="photoslist d-flex customphotoslist">
                            {Object.keys(data.images).length > 0
                              ? data.images.map((row, i) => (
                                  <div className="orderviewImage mb-2">
                                    <img
                                      className="img-fluid d-block"
                                      src={filebasepath + row.image}
                                    />
                                    <div className="text-center mt-1">
                                      <a
                                        href={filebasepath + row.image}
                                        className="btn btn-sm btn-dark d-block"
                                        target="_blank"
                                      >
                                        View
                                      </a>

                                      <button
                                        className="mt-1 btn btn-sm btn-primary d-block"
                                        onClick={() =>
                                          download(
                                            filebasepath + row.image,
                                            row.image
                                          )
                                        }
                                      >
                                        Download
                                      </button>
                                    </div>
                                  </div>
                                ))
                              : "Photos Not Available"}
                          </div>
                        </td>
                      </tr>
                      <tr className="uploaded-images-detail finish-box">
                        <th scope="row">Note</th>
                        <td>{data.note}</td>
                      </tr>

                      <tr>
                        <th scope="row">Signature</th>
                        <td>
                          <div className="finish-box signature-img">
                            <div className="img-box">
                              {data.signature_image != null && (
                                <img
                                  className="img-fluid"
                                  src={filebasepath + data.signature_image}
                                  alt=""
                                />
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr className="uploaded-images-detail finish-box">
                        <th scope="row">Additional Note</th>
                        <td>{data.additional_note}</td>
                      </tr>

                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          <h6>
                            {currencySymbol}
                            {data.amount}{" "}
                          </h6>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Discount (%)</th>
                        <td>
                          <h6>{data.discount_percentage}%</h6>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Discount Amount</th>
                        <td>
                          <h6>
                            {currencySymbol}
                            {data.discount}{" "}
                          </h6>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Override Amount</th>
                        <td>
                          <h4>
                            {" "}
                            {currencySymbol}
                            {data.override_total}
                          </h4>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Grand Total</th>
                        <td>
                          <h4>
                            {" "}
                            {currencySymbol}
                            {grandTotal(data.amount, data.discount)}
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orderview;
