import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import SidedMetalFloor1 from "../../../assets/web/images/4 Sided Metal Floor - HT.jpg";
import SidedMetalFloor2 from "../../../assets/web/images/4 Sided Metal Counter - HT.jpg";
import SidedMetalFloor3 from "../../../assets/web/images/4 Sided Floor - Light.jpg";
import SidedMetalFloor4 from "../../../assets/web/images/2 Sided Floor - Light.jpg";
import SidedMetalFloor5 from "../../../assets/web/images/4 Sided Counter - Light.jpg";
import SidedMetalFloor6 from "../../../assets/web/images/4 Sided Floor 2 Floor - Light.jpg";

import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  updateSession,
  loaderBar,
  apibaseURl,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
  getvalidationErr,
} from "../../../functions/Function";
const StepFour = ({
  inputs,
  setInputs,
  validation,
  setvalidation,
  handleChange,
  validateInputs,
  validtionError,
  step,
  stepvalidation,
  nextStep,
  backStep,
  validationCheckStepfour,
}) => {
  const custombackstep = () => { 
    if (inputs.displaytype == "display_by_keyword") {
      backStep(3);
    }
    if (inputs.displaytype == "display_plus_design") {
      backStep(3);
    }
  };
console.log(inputs);

  const customnext = () => {
    let total = 0;
    inputs.display_types.forEach((item) => {
      const quantity = parseFloat(item.qty);
      const price = parseFloat(item.display_type_metal_price);
      total += quantity * price;
    });

    if (total > 0) {
      validationCheckStepfour();
    } else {
      errorToast("Please add quantity", "top-right", "4000");
    }
  };

  function findIndexById(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        return i; // Return the index if a match is found
      }
    }
    return -1; // Return -1 if no match is found
  }

  const handleChangeqty = (event, id) => {
    const name = event.target.name;
    let qty = event.target.value;

    if (qty) {
      let inputsdata = inputs.display_types;
      let index = findIndexById(inputsdata, id);
      inputsdata[index].qty = qty;
      setInputs((values) => ({ ...values, display_types: inputsdata }));
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <ToastContainer />
      <section className="section order-detail-2 order-detail-4">
        <div className="container">
          <div className="form-box row">
            <div className="col-lg-12 inner-display-box inner-display-box-checks">
              <h2 className="form-heading">DISPLAY TYPES</h2>

              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12 inner-display-box">
                  <div className="display-box">
                    <div className="img-box">
                      <img className="img-fluid" src={SidedMetalFloor2} />
                    </div>
                  </div>
                  <div className="single-description-box  m-0">
                    <h5>{inputs.display_types[0].label}</h5>
                    <span className="design-price">
                      (${inputs.display_types[0].price_label})
                    </span>
                    <div className="d-flex">
                      {/* <button className="btn btn-sm btn-info">
                        <i className="fa fa-plus"></i>
                      </button> */}
                      <input
                        type="number"
                        className="form-control"
                        placeholder="QTY"
                        value={inputs.display_types[0].qty}
                        onChange={(event) =>
                          handleChangeqty(event, inputs.display_types[0].id)
                        }
                        min={0}
                      />
                      {/* <button className="btn btn-sm btn-info">
                        <i className="fa fa-minus"></i>
                      </button> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-12 inner-display-box text-end">
                  <div className="display-box ">
                    <div className="img-box">
                      <img className="img-fluid" src={SidedMetalFloor1} />
                    </div>
                  </div>
                  <div className="single-description-box">
                    <h5>{inputs.display_types[1].label}</h5>
                    <span className="design-price">
                      (${inputs.display_types[1].price_label})
                    </span>

                    <input
                      type="number"
                      value={inputs.display_types[1].qty}
                      className="form-control"
                      onChange={(event) =>
                        handleChangeqty(event, inputs.display_types[1].id)
                      }
                      min={0}
                      placeholder="QTY"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-12 inner-display-box">
                  <div className="display-box ">
                    <div className="img-box">
                      <img className="img-fluid" src={SidedMetalFloor5} />
                    </div>
                  </div>
                  <div className="single-description-box m-0">
                    <h5>{inputs.display_types[2].label}</h5>
                    <span className="design-price">
                      (${inputs.display_types[2].price_label})
                    </span>
                    <form action="">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="QTY"
                        value={inputs.display_types[2].qty}
                        onChange={(event) =>
                          handleChangeqty(event, inputs.display_types[2].id)
                        }
                        min={0}
                      />
                    </form>
                  </div>
                </div>
                {/* <div className="col-lg-6 col-sm-6 col-12 text-end inner-display-box">
                  <div className="display-box ">
                    <div className="img-box">
                      <img className="img-fluid" src={SidedMetalFloor4} />
                    </div>
                  </div>
                  <div className="single-description-box">
                    <h5>{inputs.display_types[3].label}</h5>
                    <span className="design-price">
                      (${inputs.display_types[3].price_label})
                    </span>
                    <form action="">
                      <input
                        type="number"
                        value={inputs.display_types[3].qty}
                        onChange={(event) => handleChangeqty(event, 3)}
                        className="form-control"
                        placeholder="QTY"
                      />
                    </form>
                  </div>
                </div> */}
                
                {/* last date comment 06-11-2024 <div className="col-lg-6 col-sm-6 col-12 inner-display-box text-end">
                  <div className="display-box ">
                    <div className="img-box">
                      <img className="img-fluid" src={SidedMetalFloor3} />
                    </div>
                  </div>
                  <div className="single-description-box">
                    <h5>{inputs.display_types[3].label}</h5>
                    <span className="design-price">
                      (${inputs.display_types[3].price_label})
                    </span>
                    <form action="">
                      <input
                        type="number"
                        value={inputs.display_types[3].qty}
                        onChange={(event) =>
                          handleChangeqty(event, inputs.display_types[3].id)
                        }
                        className="form-control"
                        placeholder="QTY"
                      />
                    </form>
                  </div>
                </div> */}
                {/* <div className="col-lg-6 col-sm-6 col-12 text-end inner-display-box">
                  <div className="display-box ">
                    <div className="img-box">
                      <img className="img-fluid" src={SidedMetalFloor6} />
                    </div>
                  </div>
                  <div className="single-description-box">
                    <h5>{inputs.display_types[5].label}</h5>
                    <span className="design-price">
                      (${inputs.display_types[5].price_label})
                    </span>
                    <form action="">
                      <input
                        type="number"
                        value={inputs.display_types[5].qty}
                        className="form-control"
                        onChange={(event) => handleChangeqty(event, 5)}
                        placeholder="QTY"
                      />
                    </form>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-lg-12  inner-display-box-checks">
              <div className="row">
                <div className="text-center">
                  <button
                    onClick={() => custombackstep()}
                    className="btn site-btn backbtn"
                  >
                    {" "}
                    <span>
                      <i className="fa-solid fa-chevron-left" disabled></i>
                    </span>{" "}
                    Back
                  </button>

                  <button onClick={() => customnext()} className="btn site-btn">
                    Next{" "}
                    <span>
                      <i className="fa-solid fa-chevron-right" disabled></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepFour;
