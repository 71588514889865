import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
const StepThree = ({
  inputs,
  setInputs,
  validation,
  setvalidation,
  handleChange,
  validateInputs,
  validtionError,
  step,
  stepvalidation,
  nextStep,
  backStep,
  validationCheckStepThree,
}) => {
  const updateDispaly = (value) => {
    setInputs((values) => ({ ...values, displaytype: value }));
    validationCheckStepThree(value);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      {console.log(inputs.displaytype)}
      <section className="section order-detail-2">
        <div className="container">
          <div className="form-box">
            <div className="text-center">
              <h2 className="form-heading">Would you like to order by</h2>
              <div className="display-keyword text-center ">
                <div
                  className={`img-box ${
                    inputs.displaytype == "display_by_keyword" && "active"
                  }`}
                  onClick={() => updateDispaly("display_by_keyword")}
                >
                  <h1 className="display-keyword-heading">
                    DISPLAY BY
                    <br /> KEYWORD
                  </h1>
                </div>
              </div>
              <div className="display-keyword display-keyword-2 text-center">
                <div
                  className={`img-box ${
                    inputs.displaytype == "display_plus_design" && "active"
                  }`}
                  onClick={() => updateDispaly("display_plus_design")}
                >
                  <h1 className="display-keyword-heading">
                    DISPLAY BY
                    <br /> DESIGN
                  </h1>
                </div>
              </div>
              <div className="display-keyword display-keyword-3 text-center">
                <div
                  className={`img-box ${
                    inputs.displaytype == "design_only" && "active"
                  }`}
                  onClick={() => updateDispaly("design_only")}
                >
                  <h1 className="display-keyword-heading">
                    DESIGNS
                    <br />
                    ONLY
                  </h1>
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                onClick={() => backStep(2)}
                className="btn site-btn backbtn"
              >
                {" "}
                <span>
                  <i className="fa-solid fa-chevron-left" disabled></i>
                </span>{" "}
                Back
              </button>
              {/* 
              <button
                onClick={() => validationCheckStepThree(3)}
                className="btn site-btn"
              >
                Next{" "}
                <span>
                  <i className="fa-solid fa-chevron-right" disabled></i>
                </span>
              </button> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepThree;
