import React, { useState, useEffect, useContext  , useRef } from "react";
import { useNavigate } from "react-router-dom";
import { WebAssetsContext } from "../../App";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import Moment from 'moment';
import  { loggedInUserDetails  , changeDateFotmate}  from "../../functions/Function";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
const Users = (props) => {
  const tableref = useRef(1);
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  const HandleClickOnEditUser = (id = '') => {
    navigate("/admin/clients/edit/" + id);
  }
  const [loading, setloading] = useState(true);
  const [users, setUsers] = useState([]);
  const fetchData = () => {
    fetch(api + "getusers")
      .then(res => res.json())
      .then((result) => {
        if (result.status == true) {
          console.log(result.response);
          setUsers(result.response);
          setloading(false);
          tableref.current = tableref+1
        }
      }
      )
  }

  const fetchDataByClientBranchID = (branch_id='') => {
    fetch(api + "getusersByBranchID?branch_id="+branch_id)
      .then(res => res.json())
      .then((result) => {
        if (result.status == true) {
          setUsers(result.response);
          setloading(false);
        }
      }
      )
  }

  const HandleClickOnDeleteUser = async (id = '') => {
    Swal.fire({
      title: 'Are you sure you want to delete it ?',
      text: "Once deleted, you will not be able to get it again!",
      icon: 'warning',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const resJson = DeleteUser(id);
          resJson.then(function (output) {
            if (output.status == true) {
              Swal.fire({
                title: 'Deleted!',
                text: "User has been deleted successfully.",
                icon: 'success'
              }).then((finalresult) => {
                if (finalresult.isConfirmed) {
                  fetchData();
                }
              });
            }
          });

        } catch (err) {
          console.log(err);
        }

      }
    });


  }
  const DeleteUser = async (id = '') => {
    const res = await fetch(api + "deleteUser", {
      method: "POST",
      body: JSON.stringify({ id: id }),
    });
    let resJson = await res.json();
    return resJson;
  }


  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);
  const HandleClickonAddWallet = (user) => {
    setShow(true)
    setUser(user)
  }

  const passportAlert = (date='') => 
  {
    var currentDate = Moment().format("DD-MM-YYYY");
    //  if(currentDate > date)
    //  {
    //     return <div className="passportExpired"> Passport Expired</div>
    //  }
    if(currentDate > date)
     {
       return <div className="text-danger bold-700">{date}</div>
    }else
    {
      return <div className="bold-700">{date}</div>
    }
    
     
  }

  const hideModal = () => {
    setShow(false);
  }
 
  useEffect(() => {
    if(loggedInUserDetails('role') == 'administrator' )
    {
      fetchData();
    }
    if(loggedInUserDetails('role') == 'staff')
    {
      fetchDataByClientBranchID(loggedInUserDetails('branch_id'));
    }
   }, [])
   
  

useEffect(() => {
  setTimeout(function () {
    $('#theme-datatable').DataTable();
}, 2000);
} , [tableref])



  return (
    <>
    
      <div className="section-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="pt-4 pl-4 pr-4">
                        <div className="card-header pl-0 pr-0 w-50 float-left">
                          <h4 className="headercolor">Clients List</h4>
                        </div>
                        <div className="float-right">
                          <button onClick={() => navigate("/admin/clients/add")} className="btn btn-icon icon-left btn-primary"><i className="fas fa-plus"></i> Add Client</button>
                        </div>
                      </div>
                      <div className="card-body pl-4 pr-4 pb-4 pt-0">
                        
                        <div className="table-responsive theme-table">
                          <table className="table table-hover table-bordered display" id="theme-datatable"  >
                            <thead>
                              <tr>
                                <th className="text-center">S.NO</th>
                                <th  width={'180px'} ><div className="actionw-20">Client Name</div></th>
                                { loggedInUserDetails('role') == 'administrator' ? 
                                  <>
                                    
                                    <th width={'180px'} ><div className="actionw-20">Email</div></th>
                                   
                                    
                                  </>
                                : 
                                  <>
                                      <th width={'180px'} >Added By</th>
                                      <th width={'180px'} >Branch</th>
                                  </>
                                }

                                

                               
                                  <>
                                    <th width={'180px'} ><div className="actionw-20">Company</div></th>
                                    <th width={'180px'} ><div className="actionw-20">PP Number</div></th>
                                    <th width={'350px'}> <div className="actionw-20">PP Expire</div></th>
                                    <th width={'350px'}> <div className="actionw-20">DOB</div></th>
                                  </>
                               


                               
                                <th className="text-right" width={'350px'}>
                                    <div className="actionw-20">Action</div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>

                              { users.length > 0 && users.map((user, i) => (

                                <tr key={i}>
                                  <td className="text-center">{i + 1}</td>
                                  <td className="text-capitalize">{user.name}</td>
                                  { loggedInUserDetails('role') == 'administrator' ? 
                                  <>
                                      <td>{user.c_email}</td>
                                      
                                  </>
                                : 
                                  <>
                                      <td>{user.added_by_name}</td>
                                      <td>{user.branch_name}</td>
                                  </>
                                }
                                

                                

                                  <>
                                    <td>{user.company_name}</td>
                                    <td>{user.passport_number}</td>
                                    <td> 
                                      
                                    {passportAlert(changeDateFotmate(user.passport_exp_date, 'DD-MM-YYYY'))}
                                    </td>
                                    <td>
                                      <>
                                      {changeDateFotmate(user.dob, 'DD-MM-YYYY')}
                                       
                                        </>
                                    </td>
                                  </>
                                 
                               
                                  
                                  <td className="text-right">

                                    <button role="button"
                                      id={'view_' + user.id} onClick={() => navigate("/admin/clients/view/" + user.id)}
                                      className="btn btn-icon btn-primary mr-2" data-tooltip-content="View Details">
                                      <i className="fas fa-eye"></i>
                                    </button>
                                    <Tooltip anchorId={'view_' + user.id} />
                                    
                                    <button role="button"
                                      id={'edit_' + user.id} onClick={() => HandleClickOnEditUser(user.id)}
                                      className="btn btn-icon btn-primary mr-2" data-tooltip-content="Edit">
                                      <i className="far fa-edit"></i>
                                    </button>
                                    <Tooltip anchorId={'edit_' + user.id} />
                                    { loggedInUserDetails('role') == 'administrator' &&
                                        <>
                                            <button role="button" id={'delete_' + user.id} onClick={() => HandleClickOnDeleteUser(user.id)} className="btn btn-icon btn-danger" data-tooltip-content="Delete" ><i className="far fa-trash-alt"></i></button>
                                            <Tooltip anchorId={'delete_' + user.id} />
                                        </>
                                    }
                                  </td>
                                </tr>
                               
                              )) 
                              }

                              

                            </tbody>
                          </table>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
     
    </>

  );
}

export default Users;
