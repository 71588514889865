import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  updateSession,
  loaderBar,
  apibaseURl,
  filebasepath,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
  getvalidationErr,
} from "../../../functions/Function";
const StepSix = ({
  inputs,
  setInputs,
  validation,
  setvalidation,
  handleChange,
  validateInputs,
  validtionError,
  step,
  stepvalidation,
  nextStep,
  backStep,
  validationCheckStepSix,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [bigImage, setbigImage] = useState("false");
  const [uploadedfile, setuploadedfile] = useState(null);
  const [progress, setProgress] = useState(false);
  const [progresscount, setProgresscount] = useState(0);
  const [variant, setVariant] = useState("danger");
  const fileSelectedHandler = (e) => {
    var getfiles = e.target.files;
    let totalFiles = e.target.files.length;
    if (totalFiles == 0) {
      return false;
    }
    let data = new FormData();
    for (let index = 0; index < totalFiles; index++) {
      data.append("files[]", getfiles[index]);
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        setProgress(true);
        setVariant("danger");
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgresscount(percentCompleted);
      },
    };
    axios
      .post(apibaseURl + "uploadfile", data, config)
      .then(function (res) {
        if (res.status == 200 && res.data.status == true) {
          // setuploadedfile(res.data.response);
          // setuploadedfile((prevData) => [...prevData, ...res.data.response]);
          console.log("images");
          console.log(res.data.response);
          setProgress(false);
          setVariant("success");
          setInputs((values) => ({
            ...values,
            files: [...values.files, ...res.data.response],
          }));
        }
      })
      .catch((err) => console.log(err));
  };
  const custombackstep = () => {
    if (inputs.displaytype == "display_by_keyword") {
      backStep(5);
    }
    if (
      inputs.displaytype == "display_plus_design" ||
      inputs.displaytype == "design_only"
    ) {
      backStep(9);
    }
  };

  function showBigImageModel(image) {
    setModalShow(true);
    setbigImage(image);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ImageBigSizeViewer
        show={modalShow}
        onHide={() => setModalShow(false)}
        bigImage={bigImage}
      />
      <section className="section order-detail-2">
        <div className="container">
          <div className="form-box row">
            <div className="col-lg-12  inner-display-box-checks progress-bar-box">
              <div className="row">
                <div className="col-lg-12 col-sm-12 ">
                  <div className="text-center">
                    <h5>
                      If you have any photos or additional assets that need to
                      be uploaded you can upload them now.
                    </h5>
                  </div>
                  <div className="input-digits-box mt-4 overflow">
                    <div className="file-upload">
                      <div className="image-upload-wrap">
                        <input
                          className="file-upload-input"
                          type="file"
                          multiple
                          onChange={fileSelectedHandler}
                          accept="image/*"
                        />
                        <div className="uplaoding-icon">
                          <i className="fa-solid fa-upload"></i>
                        </div>
                        <div className="drag-text">
                          <h5>
                            Click here to upload photos or take photos to add to
                            this order
                          </h5>
                        </div>
                      </div>
                      {progress == true && (
                        <div className="my-3">
                          {" "}
                          <ProgressBar
                            animated
                            now={progresscount}
                            label={`${progresscount}%`}
                            variant={variant}
                          />
                        </div>
                      )}
                      {inputs.files != null && (
                        <div className="uploadPhotos">
                          {Object.keys(inputs.files).length > 0
                            ? inputs.files.map((row, i) => (
                                <Link
                                  // href={filebasepath + row.name}
                                  onClick={() =>
                                    showBigImageModel(filebasepath + row.name)
                                  }
                                >
                                  <div key={i}>
                                    <img
                                      className="file-upload-image"
                                      src={filebasepath + row.name}
                                    />
                                  </div>
                                </Link>
                              ))
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12 ">
                  <div className="input-digits-box mt-4 overflow">
                    <div className="form-text-area-6 file-upload">
                      <div className="mb-3 form-input-box ">
                        <label className="form-label">
                          <h5>NOTE</h5>
                        </label>
                        <div className="form-input-box">
                          <textarea
                            className="form-control"
                            rows="6"
                            name="note"
                            value={inputs.note || ""}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    onClick={() => custombackstep()}
                    className="btn site-btn backbtn"
                  >
                    {" "}
                    <span>
                      <i className="fa-solid fa-chevron-left" disabled></i>
                    </span>{" "}
                    Back
                  </button>

                  <button
                    onClick={() => validationCheckStepSix()}
                    className="btn site-btn"
                  >
                    Next{" "}
                    <span>
                      <i className="fa-solid fa-chevron-right" disabled></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  function ImageBigSizeViewer(propss) {
    return (
      <Modal
        size="lg"
        show={propss.show}
        onHide={() => propss.onHide(false)}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={propss.bigImage} className="w-100" />
        </Modal.Body>
      </Modal>
    );
  }
};

export default StepSix;
