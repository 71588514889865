import React , { useState , useEffect , useContext } from "react";
import { Link } from "react-router-dom";
import box1 from '../../assets/img/banner/1.png';
import box2 from '../../assets/img/banner/2.png';
import box3 from '../../assets/img/banner/3.png';
import box4 from '../../assets/img/banner/4.png';
import  { WebAssetsContext }  from "../../App";
import { loggedInUserDetails , alertDanger , apibaseURl , errorToast , successToast  , getvalidationErr , currencySymbol} from "../../functions/Function";
const  Dashboard = (props) => {
const WebAssets = useContext(WebAssetsContext);
const api = WebAssets.apiURL;
const [dashboarddata, setdashboarddata] = useState(null);
const fetchData = () => {
  fetch(apibaseURl+"dashboard")
    .then(res => res.json())
    .then((result) => {
        if(result.status == true)
        {
          console.log(result.response);
          setdashboarddata(result.response);
        }
      }
    )
  }

  useEffect(() => {
    fetchData();

},[])
  return (
    <>
      {/* {console.log(dashboarddata.lasted_users)} */}
      
      {dashboarddata != null ? 
        <div>
         
         <div className="row">
              <div className="col-12">
                <h4>Hello {loggedInUserDetails('name')} , Welcome To Order Management System</h4>
              </div>
             
              <div className="col-xl-4 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Total Staff</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_staff}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-green text-white">
                          <i className="fas fa-user-check"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                     
                      <span className="text-nowrap">Since All Time</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Active Staff</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_active_staff}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-green text-white">
                          <i className="fas fa-user-check"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                     
                      <span className="text-nowrap text-success">Active Staff</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Inactive Staff</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_inactive_staff}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-green text-white">
                          <i className="fas fa-user-check"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                     
                      <span className="text-nowrap text-danger">Inactive Staff</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Total Orders</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_orders}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-purple text-white">
                        <i className="fa-brands fa-first-order"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-info  text-sm">
                  
                      <span className="text-nowrap display-price">{currencySymbol}{dashboarddata.total_orders_amount}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Completed Orders</h6>
                        <span className="font-weight-bold mb-0">{dashboarddata.total_completed_order}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-purple text-white">
                        <i className="fa-brands fa-first-order"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-info text-sm">
                  
                      <span className="text-nowrap text-success display-price">{currencySymbol}{dashboarddata.total_completed_order_amount}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6">
                <div className="card">
                  <div className="card-body card-type-3">
                    <div className="row">
                      <div className="col">
                        <h6 className="text-muted mb-0">Canceled Order</h6>
                        <span className="font-weight-bold mb-0 display-price">{dashboarddata.total_canceled_order}</span>
                      </div>
                      <div className="col-auto">
                        <div className="card-circle l-bg-purple text-white">
                        <i className="fa-brands fa-first-order"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-muted text-sm">
                  
                      <span className="text-nowrap text-danger display-price">{currencySymbol}{dashboarddata.total_canceled_order_amount}</span>
                    </p>
                  </div>
                </div>
              </div>

              


            </div>
         
      </div>
      : '' }
                
    </>

  );
}

export default Dashboard;
