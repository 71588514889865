import React, {
  Suspense,
  lazy,
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  updateSession,
  loaderBar,
  miniloaderBar,
  apibaseURl,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
  getvalidationErr,
  changeDateFotmate,
} from "../../functions/Function";
const Orders = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [laodder, setlaodder] = useState(true);

  const getLoggedInUserDetails = async () => {
    let response = await fetch(
      apibaseURl + "orders/" + loggedInCustomerDetails("id")
    );
    if (response.status === 200) {
      let result = await response.json();
      setlaodder(false);
      if (result.result == true) {
        setData(result.response);
      }
    }
  };

  const getOrder = async (order_id) => {
    let res = await fetch(apibaseURl + "getorder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: loggedInCustomerDetails("id"),
        order_id: order_id,
      }),
    });
    let output = await res.json();
    console.log(output);
    if (res.status === 200) {
      if (output?.result === true && output.response != false) {
        props.setupdateorderId(order_id);
        props.updateInputState(output.response);
        navigate("/");
      }
    }
  };

  const grandTotal = (amount = 0, discount = 0) => {
    const totalAmount = Number(amount);
    const totalDiscount = Number(discount);
    const result = (totalAmount - totalDiscount).toFixed(2);
    return result;
  };

  useEffect(() => {
    props.setupdateorderId(0);
    getLoggedInUserDetails();
  }, []);
  return (
    <>
      <section className="section registratio-box ">
        <div className="container">
          <div className="form-box box-shadow">
            <div className="box-header">
              <h2 className="form-heading">Orders</h2>
              <div>
                <Link to="/" className="btn btn-md btn-dark">
                  <i className="fa-solid fa-plus"></i> Create Order
                </Link>
              </div>
            </div>
            {laodder == true && miniloaderBar(100, 180)}
            {laodder == false && (
              <table className="table my-table table-striped">
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Order ID</th>
                    <th scope="col">Reorder</th>
                    <th scope="col">Buyer Info</th>
                    {/* <th scope="col">Company Name</th> */}
                    {/* <th scope="col">Buyer Email</th>
                    <th scope="col">Buyer Phone </th> */}

                    <th scope="col">Delivery Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((row, i) => (
                      <tr>
                        <td data-label="S.No ">{i + 1}</td>
                        <td data-label="Order ID">
                          <small className="discountcolor">#{row.id}</small>
                        </td>
                        <td data-label="Reorder">
                          {row.reorder == "Yes" && (
                            <span class="badge bg-info">Yes</span>
                          )}
                          {row.reorder == "No" && (
                            <span class="badge bg-dark">No</span>
                          )}
                        </td>

                        <td data-label="Buyer Info">
                          <div>
                            <b> {row.buyer_name}</b>
                          </div>
                          <small className="d-block">
                            {" "}
                            <i className="fa-solid fa-envelope"></i>{" "}
                            {row.buyer_email != ""
                              ? row.buyer_email
                              : "Not Available"}
                          </small>
                          <small className="d-block">
                            <i className="fa-solid fa-square-phone"></i>{" "}
                            {row.buyer_phone != ""
                              ? row.buyer_phone
                              : "Not Available"}
                          </small>
                        </td>
                        {/* <td data-label="Company Name">{row.company_name}</td> */}
                        {/* <td data-label="Buyer Email">{row.buyer_email}</td>
                        <td data-label="Buyer Phone ">{row.buyer_phone}</td> */}
                        <td data-label="Delivery Date">
                          <div>
                            {row.delivery_date != "0000-00-00" ? (
                              changeDateFotmate(row.delivery_date, "DD-MM-YYYY")
                            ) : (
                              <>
                                {row.delivery_type == "asready" && "As Ready"}
                                {row.delivery_type == "asap" && "ASAP - RUSH"}
                              </>
                            )}
                          </div>
                        </td>
                        <td data-label="Amount ">
                          {currencySymbol}
                          {row.amount}
                        </td>
                        <td data-label="Discount ">
                          {currencySymbol}
                          {row.discount}{" "}
                          <span className="badge bg-dark">
                            {row.discount_percentage}%
                          </span>
                        </td>
                        <td data-label="Total Amount ">
                          {currencySymbol}
                          {grandTotal(row.amount, row.discount)}
                        </td>
                        <td data-label="Status">
                          {row.status == "Completed" && (
                            <span className="badge bg-success">Completed</span>
                          )}
                          {row.status == "Canceled" && (
                            <span className="badge bg-danger">Canceled</span>
                          )}
                        </td>
                        <td data-label="Action ">
                          {row.status == "Completed" && (
                            <button
                              onClick={() => getOrder(row.id)}
                              className="btn btn-sm site-btn m-1"
                            >
                              Edit{" "}
                              <span>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </span>
                            </button>
                          )}

                          <button
                            onClick={() => navigate("/orders/" + row.id)}
                            className="btn btn-sm site-btn"
                          >
                            View{" "}
                            <span>
                              <i className="fa-solid fa-eye"></i>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        Data not yet found.{" "}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Orders;
