import Moment from "moment";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { Link, NavLink, Outlet } from "react-router-dom";
import axios from "axios";
import { Bars, InfinitySpin } from "react-loader-spinner";

export const changeDateFotmate = (date = "", format = "") => {
  return Moment(date).format(format);
};
export const currencySymbol = "$";

// export const filebasepath = "https://p.softechplanet.com/oms/uploads/";
// export const apibaseURl = "https://p.softechplanet.com/oms/api/";
export const filebasepath = "https://po.stickerpack.com/server/uploads/";
export const apibaseURl = "https://po.stickerpack.com/server/api/";

//export const filebasepath = "https://oms.softechplanet.com/server/uploads/";
//export const apibaseURl = "https://oms.softechplanet.com/server/api/";

export const loggedInUserDetails = (key = "") => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("admin"));
  if (getuserloggedIn) {
    if (
      getuserloggedIn.user[key] != undefined &&
      getuserloggedIn.user[key] != ""
    ) {
      return getuserloggedIn.user[key];
    }
  }
  return false;
};

export const getLoggedInuserRole = () => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("admin"));
  if (
    getuserloggedIn.user["role"] != undefined &&
    getuserloggedIn.user["role"] != ""
  ) {
    return getuserloggedIn.user["role"];
  }

  return false;
};

export const getStaffRole = () => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
  if (
    getuserloggedIn?.user["role"] != undefined &&
    getuserloggedIn?.user["role"] != ""
  ) {
    return getuserloggedIn.user["role"];
  }

  return false;
};

export const loggedInCustomerDetails = (key = "") => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
  if (getuserloggedIn) {
    if (
      getuserloggedIn.user[key] != undefined &&
      getuserloggedIn.user[key] != ""
    ) {
      return getuserloggedIn.user[key];
    }
  }
  return false;
};

export const updateSession = (key = "", value = "") => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("customer"));

  if (getuserloggedIn) {
    getuserloggedIn["user"][key] = value;
    localStorage.setItem("customer", JSON.stringify(getuserloggedIn));
  }
  return false;
};

export const apiCallpost = async (url, data) => {
  let res = await fetch(apibaseURl + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return res;
};

export const alertDanger = (message) => {
  return (
    <div className="alert ivo-alert-text alert-danger" role="alert">
      <div className="alert-container align-items-center vertical-align">
        <div className="alertbox-1">
          <i className="fa fa-exclamation-triangle "></i>
        </div>
        <div className="alertbox-2">
          <div className="ivo-alert-content">
            <strong>Alert:</strong> {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export const customalert = (message, type = "warning", link = "") => {
  let icon = "";
  let text = "";
  let customclassName = "";
  if (type == "warning") {
    icon = "fa fa-exclamation-triangle";
    text = "Alert:";
    customclassName = "alert-warning";
  } else if (type == "success") {
    icon = "fa-solid fa-circle-check";
    text = "Success:";
    customclassName = "alert-success";
  } else if (type == "info") {
    icon = "fa-solid fa-circle-info";
    text = "Information:";
    customclassName = "alert-info";
  } else {
    icon = "fa fa-exclamation-triangle";
    text = "Alert:";
    customclassName = "alert-danger";
  }
  return (
    <div className={"alert ivo-alert-text " + customclassName} role="alert">
      <div className="alert-container align-items-center vertical-align">
        <div className="alertbox-1">
          <i className={icon}></i>
        </div>
        <div className="alertbox-2">
          <div className="ivo-alert-content">
            <strong>{text}</strong> {message}
            {link && (
              <Link to={`${link}`} className="alertLink">
                Click Here
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const alertSuccess = (message) => {
  return (
    <div className="alert ivo-alert-text alert-success" role="alert">
      <div className="alert-container align-items-center vertical-align">
        <div className="alertbox-1">
          <i className="fa-solid fa-circle-check"></i>
        </div>
        <div className="alertbox-2">
          <div className="ivo-alert-content">
            <strong>Success:</strong> {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export const errorToast = (
  text,
  positions = "top-right",
  autoClose = "3000"
) => {
  if (positions != "") {
    positions = positions;
  }
  toast.error(text, {
    autoClose: autoClose,
    position: positions,
    transition: Zoom,
    theme: "colored",
    closeOnClick: true,
    pauseOnHover: true,
  });
};

export const successToast = (
  text,
  positions = "top-right",
  autoClose = "3000",
  hideProgressBar = false
) => {
  if (positions != "") {
    positions = positions;
  }
  toast.success(text, {
    autoClose: autoClose,
    position: positions,
    transition: Zoom,
    theme: "colored",
    closeOnClick: true,
    pauseOnHover: true,
    hideProgressBar: hideProgressBar,
  });
};

export const warningToast = (
  text,
  positions = "top-right",
  autoClose = "3000",
  hideProgressBar = false
) => {
  if (positions != "") {
    positions = positions;
  }
  toast.warning(text, {
    autoClose: autoClose,
    position: positions,
    transition: Zoom,
    theme: "colored",
    closeOnClick: true,
    pauseOnHover: true,
    hideProgressBar: hideProgressBar,
  });
};

export const loaderBar = () => {
  return (
    <div className="loaderapp-container">
      <InfinitySpin width="200" color="#007bff" />
    </div>
  );
};

export const miniloaderBar = (width = "100", height = "100") => {
  return (
    <div
      className="miniloader"
      style={{ height: height + "px", width: width + "%" }}
    >
      <InfinitySpin width="200" color="#007bff" />
    </div>
  );
};

export const getvalidationErr = (errors) => {
  const errorsConvertInArry = Object.values(errors);
  let collectAllValidationErr = [];
  errorsConvertInArry.forEach((error, index) => {
    collectAllValidationErr.push(<div key={index}>{error}</div>);
  });
  const storeAllValidtionErr = <>{collectAllValidationErr}</>;

  return storeAllValidtionErr;
};
