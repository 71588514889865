import React , { useState  } from "react";
import { Link } from "react-router-dom";

const  Notfound = (props) => {
  return (
    <>
       <div className="container mt-5">
        <div className="page-error">
          <div className="page-inner">
            <h1>404</h1>
            <div className="page-description">
              The page you were looking for could not be found.
            </div>
            <div className="page-search">
            
              <div className="mt-3">
                <Link to="/admin/dashboard">Back to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default Notfound;
