import React, {
  useState,
  useEffect,
  createContext,
  lazy,
  Suspense,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Redirect,
  Route,
  Link,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  updateSession,
  loaderBar,
  apibaseURl,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
  getStaffRole,
} from "./functions/Function";
import Webassets from "./Webassets";
import Home from "./web/Home";
import Dashboard from "./web/myaccount/Dashboard";
import Profile from "./web/myaccount/Profile";
import Orders from "./web/myaccount/Orders";
import Order from "./web/myaccount/Order";
import Changepassword from "./web/myaccount/Changepassword";
const WebContext = createContext();

const Signin = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./web/Signin")), 2000);
  });
});

const Layout = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./web/layout/Layout")), 2000);
  });
});

const Webroute = (props) => {
  let getwebUserloggedIn = JSON.parse(localStorage.getItem("user"));
  const [loggedInuser, setloggedInuser] = useState(getwebUserloggedIn);

  const [customer, setcustomer] = useState({});
  const [updateinputs, setUpdateInputs] = useState({});
  const [updateorderId, setupdateorderId] = useState(0);

  const loggedInuserdata = () => {
    let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
    if (getuserloggedIn) {
      setloggedInuser(getuserloggedIn);
      getLoggedInUserDetails();
    } else {
      setloggedInuser(null);
    }
  };

  const Logout = () => {
    localStorage.removeItem("user");
    let getwebUserloggedIn = JSON.parse(localStorage.getItem("user"));
    setloggedInuser(null);
    setcustomer({});
  };

  const Webprotected = ({ children }) => {
    let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
    if (getuserloggedIn != null) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  const Privateprotected = ({ children }) => {
    let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
    if (getuserloggedIn == null && getStaffRole != "staff") {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  const getLoggedInUserDetails = () => {
    fetch(apibaseURl + "user/" + loggedInCustomerDetails("id"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: loggedInCustomerDetails("id"),
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result == true) {
          setcustomer(result.response);
        }
      });
  };

  const updateInputState = (allinputdata) => {
    setUpdateInputs(allinputdata);
  };

  let WebContextdata = {};

  useEffect(() => {
    getLoggedInUserDetails();
  }, []);

  return (
    <WebContext.Provider value={WebContextdata}>
      <Webassets />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Suspense fallback={loaderBar()}>
              <Privateprotected>
                <Layout Logout={Logout} />
              </Privateprotected>
            </Suspense>
          }
        >
          <Route
            index
            path="/"
            element={
              <Privateprotected>
                <Home
                  updateinputs={updateinputs}
                  updateorderId={updateorderId}
                  setupdateorderId={setupdateorderId}
                  updateInputState={updateInputState}
                />{" "}
              </Privateprotected>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Privateprotected>
                <Dashboard
                  customer={customer}
                  getLoggedInUserDetails={getLoggedInUserDetails}
                />
              </Privateprotected>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <Privateprotected>
                <Profile />{" "}
              </Privateprotected>
            }
          />
          <Route
            exact
            path="/orders"
            element={
              <Privateprotected>
                {" "}
                <Orders
                  updateInputState={updateInputState}
                  setupdateorderId={setupdateorderId}
                />{" "}
              </Privateprotected>
            }
          />
          <Route
            exact
            path="/orders/:id"
            element={
              <Privateprotected>
                {" "}
                <Order />{" "}
              </Privateprotected>
            }
          />
          <Route
            exact
            path="/change-password"
            element={
              <Privateprotected>
                {" "}
                <Changepassword />{" "}
              </Privateprotected>
            }
          />
        </Route>

        <Route
          exact
          path="/login"
          element={
            <Suspense fallback={loaderBar()}>
              <Webprotected>
                <Signin loggedInuserdata={loggedInuserdata} />
              </Webprotected>
            </Suspense>
          }
        />
      </Routes>
    </WebContext.Provider>
  );
};

export default Webroute;
export { WebContext };
