import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Matte from "../../../assets/web/images/Matte.png";
import Glossy from "../../../assets/web/images/Glossy.png";
import HangTagged from "../../../assets/web/images/Hang Tagged.png";
import USA from "../../../assets/web/images/Made in the USA.png";
import Barcoded from "../../../assets/web/images/Barcoded.png";
import PrePriced from "../../../assets/web/images/Pre Priced.png";
const StepTwo = ({
  inputs,
  setInputs,
  validation,
  setvalidation,
  handleChange,
  validateInputs,
  validtionError,
  step,
  stepvalidation,
  nextStep,
  backStep,
  validationCheckStepTwo,
}) => {
  const updatefinish = (value) => {
    setInputs((values) => ({ ...values, finish: value }));
  };

  const updateHangtang = (e) => {
    setInputs((values) => ({ ...values, hangtag: e.target.value }));
  };
  const updatemadeinusa = (e) => {
    setInputs((values) => ({ ...values, madeinusa: e.target.value }));
  };
  const updatebarcode = (e) => {
    setInputs((values) => ({ ...values, barcode: e.target.value }));
  };
  const updateprepriced = (e) => {
    setInputs((values) => ({ ...values, prepriced: e.target.value }));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // console.log(inputs);
  return (
    <>
      <section className="section order-detail-2">
        <div className="container">
          <div className="row form-box">
            <div className="col-lg-12 inner-display-box">
              <h2 className="form-heading">FINISH</h2>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-6">
                  <div
                    className={`display-box ${
                      inputs.finish == "Matte" && "active"
                    }`}
                    onClick={() => updatefinish("Matte")}
                  >
                    <div className="display-box-heading">Matte</div>
                    <div className="img-box">
                      <img className="img-fluid" src={Matte} alt="" />
                    </div>
                    <div>
                      <button type="submit" className="btn site-btn">
                        {" "}
                        {inputs.finish == "Matte" ? "SELECTED" : "SELECT"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-6 text-end">
                  <div
                    className={`display-box ${
                      inputs.finish == "Glossy" && "active"
                    }`}
                    onClick={() => updatefinish("Glossy")}
                  >
                    <div className="display-box-heading">Glossy</div>
                    <div className="img-box">
                      <img className="img-fluid" src={Glossy} alt="" />
                    </div>
                    <div>
                      <button type="submit" className="btn site-btn">
                        {inputs.finish == "Glossy" ? "SELECTED" : "SELECT"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 inner-display-box inner-display-box-checks">
              <h2 className="form-heading">HANGTAG</h2>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-6">
                  <div className="display-box ">
                    <div className="img-box">
                      <img className="img-fluid" src={HangTagged} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-6 d-flex align-items-center">
                  <div className="display-chek-box">
                    <div className="form-check check-box">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hangtang"
                        value="Yes"
                        checked={inputs.hangtag === "Yes"}
                        onChange={updateHangtang}
                        id="flexRadioDefault7"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault7"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check check-box">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hangtang"
                        value="No"
                        checked={inputs.hangtag === "No"}
                        onChange={updateHangtang}
                        id="flexRadioDefault8"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault8"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 inner-display-box inner-display-box-checks">
              <h2 className="form-heading">MADE IN THE USA</h2>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-6">
                  <div className="display-box ">
                    <div className="img-box">
                      <img className="img-fluid" src={USA} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-6 d-flex align-items-center">
                  <div className="display-chek-box">
                    <div className="form-check check-box">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="madeinusa"
                        value="Yes"
                        checked={inputs.madeinusa === "Yes"}
                        onChange={updatemadeinusa}
                        id="flexRadioDefault6"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault6"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check check-box">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="madeinusa"
                        value="No"
                        checked={inputs.madeinusa === "No"}
                        onChange={updatemadeinusa}
                        id="flexRadioDefault5"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault5"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 inner-display-box inner-display-box-checks">
              <h2 className="form-heading">BARCODE</h2>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-6">
                  <div className="display-box ">
                    <div className="img-box">
                      <img className="img-fluid" src={Barcoded} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-6 d-flex align-items-center">
                  <div className="display-chek-box">
                    <div className="form-check check-box">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="barcode"
                        value="Yes"
                        checked={inputs.barcode === "Yes"}
                        onChange={updatebarcode}
                        id="flexRadioDefault4"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault4"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check check-box">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="barcode"
                        value="No"
                        checked={inputs.barcode === "No"}
                        onChange={updatebarcode}
                        id="flexRadioDefault3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault3"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 inner-display-box inner-display-box-checks">
              <h2 className="form-heading">PRE PRICED</h2>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-6">
                  <div className="display-box ">
                    <div className="img-box">
                      <img className="img-fluid" src={PrePriced} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-6 d-flex align-items-center pre-priced-box">
                  <form>
                    <div className="display-chek-box">
                      <div className="form-check check-box">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="prepriced"
                          value="Yes"
                          checked={inputs.prepriced === "Yes"}
                          onChange={updateprepriced}
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check check-box">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="prepriced"
                          value="No"
                          checked={inputs.prepriced === "No"}
                          onChange={updateprepriced}
                          id="flexRadioDefault2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    {inputs.prepriced == "Yes" && (
                      <div className="mt-2 form-input-box">
                        <label className="form-label">
                          IF YES, What prices? (Write in answer)
                        </label>
                        <span className="form-star"></span>
                        <div className="form-input-box">
                          <input
                            type="number"
                            name="price"
                            value={inputs.price || ""}
                            onChange={handleChange}
                            placeholder="Price"
                            className="form-control"
                          />
                          <div className="form-icon">
                            <i className="fa-solid fa-tag"></i>
                          </div>
                        </div>
                        <div
                          id="emailHelp"
                          className="form-text text-danger d-none"
                        >
                          We'll never share your email with anyone else.
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3 form-input-box ">
                    <label className="form-label">Requested Name Drop</label>
                    <span className="form-star"></span>
                    <div className="form-input-box">
                      <textarea
                        className="form-control height-100"
                        placeholder="Requested Name Drop"
                        name="requested_name_drop"
                        style={{ height: "30px" }}
                        value={inputs.requested_name_drop || ""}
                        onChange={handleChange}
                      ></textarea>
                      <div className="form-icon">
                        <i className="fa-sharp fa-solid fa-location-dot"></i>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 form-input-box ">
                    <label className="form-label">Other</label>
                    <span className="form-star"></span>
                    <div className="form-input-box">
                      <textarea
                        className="form-control height-100"
                        placeholder="Other"
                        name="other"
                        value={inputs.other || ""}
                        onChange={handleChange}
                      ></textarea>
                      <div className="form-icon">
                        <i className="fa-sharp fa-solid fa-location-dot"></i>
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      onClick={() => backStep(1)}
                      className="btn site-btn backbtn"
                    >
                      {" "}
                      <span>
                        <i className="fa-solid fa-chevron-left" disabled></i>
                      </span>{" "}
                      Back
                    </button>

                    <button
                      onClick={() => validationCheckStepTwo()}
                      className="btn site-btn"
                    >
                      Next{" "}
                      <span>
                        <i className="fa-solid fa-chevron-right" disabled></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepTwo;
