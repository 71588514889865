import React, {
  Suspense,
  lazy,
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import shopping from "../../assets/web/images/shopping-bag.png";
import delivery from "../../assets/web/images/delivery.png";
import {
  updateSession,
  loaderBar,
  apibaseURl,
  miniloaderBar,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
} from "../../functions/Function";
const Dashboard = (props) => {
  const [customer, setcustomer] = useState(null);
  const getLoggedInUserDetails = async () => {
    let response = await fetch(
      apibaseURl + "user/" + loggedInCustomerDetails("id")
    );
    if (response.status === 200) {
      let result = await response.json();
      if (result.result == true) {
        setcustomer(result.response);
      }
    }
  };
  useEffect(() => {
    getLoggedInUserDetails();
  }, []);
  return (
    <>
      <section className="section registratio-box ">
        <div className="container">
          <div className="form-box box-shadow">
            <h2 className="form-heading">Dashboard</h2>
            {customer == null && miniloaderBar(100, 180)}
            {customer != null && (
              <div className="dashboard-box ">
                <div className="dash-content ">
                  <h5 className="dash-heading">Total Order</h5>
                  <div className="dash-content-box">
                    <div className="dash-inner-heading">
                      {customer.total_completed_orders}
                    </div>
                    <div className="img-box">
                      <img className="img-fluid" src={shopping} alt="" />
                    </div>
                  </div>
                </div>
                <div className="dash-content box-shadow ">
                  <h5 className="dash-heading"> Cancel Order</h5>
                  <div className="dash-content-box">
                    <div className="dash-inner-heading">
                      {customer.total_canceled_orders}
                    </div>
                    <div className="img-box">
                      <img className="img-fluid" src={delivery} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
