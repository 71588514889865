
import React, { Suspense, lazy, useRef, useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { updateSession, loaderBar, miniloaderBar , apibaseURl, currencySymbol, errorToast, successToast, loggedInCustomerDetails , getvalidationErr } from "../../functions/Function";
const  Profile = (props) => {
  const [customer, setcustomer] = useState(null)
  const [disabled, setDisabled] = useState(false);
  const [inputs, setInputs]  = useState(
    {
      "user_id":loggedInCustomerDetails("id"),
      "name":'',
      "email":''
    }
    )

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = async (event) => {
      event.preventDefault();
      setDisabled(true);
      try {
        let res = await fetch(apibaseURl + "updateUserprofile", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(inputs),
        });
        let output = await res.json();
      
        setDisabled(false);
        if (res.status === 200) {
          
          if(output.result == true)
          {
              successToast(output.msg , 'top-right' ,'6000');
           }else
          {
            if(output.validation == true)
            {
              errorToast(getvalidationErr(output.response) , 'top-right' ,'6000');
            }else
            {
              errorToast(output.msg , 'top-right' ,'6000');
           }
          }
  
        }
      } catch (err) {
        setDisabled(false);
      }
    }
    
  const getLoggedInUserDetails = async () => {
    let response = await fetch(apibaseURl+'user/'+loggedInCustomerDetails("id"));
    if (response.status === 200) {
          let result = await response.json();
          if(result.result == true)
          {
            setInputs(values => ({ ...values, name: result.response.full_name }))
            setInputs(values => ({ ...values, email: result.response.email }))
            setcustomer(result.response);
          }
      }
    
  };
  useEffect(() => {
    getLoggedInUserDetails();
}, []);
  return (
        <>
           <ToastContainer />
          <section className="section registratio-box ">
              <div className="container">
                  <div className="form-box box-shadow">
                      <h2 className="form-heading">
                          Edit Profile
                        </h2>
                      {customer == null && miniloaderBar(100 ,  180)}
                      {customer != null &&
                          <form method="POST" onSubmit={handleSubmit}>
                          
                            <div className="mb-3 form-input-box">
                              <label className="form-label">Name</label><span className="form-star">
                              </span>
                              <div className="form-input-box">
                                
                                  <input
                                  type="text"
                                  name="name"
                                  value={inputs.name || ""}
                                  onChange={handleChange}
                                  placeholder="Name"
                                  className="form-control"
                              />
                                  <div className="form-icon">
                                    <i className="fa-solid fa-user"></i>
                                  </div>
                              </div>
                            
                            </div>
                            <div className="mb-3 form-input-box">
                              <label className="form-label">Email</label><span className="form-star">
                              </span>
                              <div className="form-input-box">
                                
                                  <input
                                    type="text"
                                    value={inputs.email || ""}
                                    placeholder="Email"
                                    className="form-control"
                                    readOnly={true}
                                  />
                                  <div className="form-icon">
                                    <i className="fa-solid fa-envelope"></i>
                                  </div>
                              </div>
                              
                            </div>
                            <div className="text-center">
                                <button className="btn site-btn" type="submit" disabled={disabled}> {disabled == false ? 'Update' : 'Updating...'} <span><i
                                  className="fa-solid fa-chevron-right"></i></span> </button>
                            </div>
                        </form>
                      } 
                  </div>
              </div>
            </section>
    </>

  );
}

export default Profile;
