import React , { useState , useEffect , useContext } from "react";
import { Link , NavLink , useParams , useNavigate  } from "react-router-dom";
import Header from '../components/admin/layout/Header';
import Sidebar from '../components/admin/layout/Sidebar';
import Footer from '../components/admin/layout/Footer';
import Swal from 'sweetalert2';
import  { WebAssetsContext }  from "../App";
import { ToastContainer, toast , Zoom } from 'react-toastify';
import   Uploadfile   from "../components/Uploadfile";
import  { apibaseURl , filebasepath , loggedInUserDetails }  from "../functions/Function";

const  Profile = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const adminLogginId = WebAssets.adminLogginId;
  const navigate = useNavigate();
  const params = useParams();
  const [disabled, setDisabled] = useState(false);
  const [id, setId] = useState(loggedInUserDetails('id'));
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  
  const [oldpwd, setOldpwd] = useState("");
  const [newpwd, setNewpwd] = useState("");
  const [confirmnewpwd, setConfirmnewpwd] = useState("");

  const [isActive, setIsActive] = useState("profile");
  
  const errortoast = (text) => 
  {
    toast.error(text ,
       {
          autoClose: 3000,
          transition:Zoom,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: true,
        }
      );
  };


  const triggerTab = (activetab) =>
  {
    setIsActive(activetab);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      let res = await fetch(apibaseURl+"updateProfile", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          name: name,
        }),
      });
      let output = await res.json();
      setDisabled(false);
      if (res.status === 200) {
          if(output.status === 2)
          {
            getvalidationErr(output.response);
          }
          if(output.status === true)
          {
            Swal.fire({  
                    title: 'Success',  
                    text: 'Profile updated successfully!',
                    icon: 'success'
                  }).then((result) => {
                    if (result.isConfirmed) {

                      let user = {
                        'token' : output.response.id,
                        'user' : output.response,
                      }
                      localStorage.setItem("admin", JSON.stringify(user));
                      setName(output.response.name);
                      setEmail(output.response.email);
                      getUser();
                    } 
                  });
          }
      }
    } catch (err) {
      setDisabled(false);
    }
  };

  const getvalidationErr = (errors) =>
  {
    const errorsConvertInArry = Object.values(errors);
    let collectAllValidationErr = [];
    errorsConvertInArry.forEach((error, index) => {
      collectAllValidationErr.push(<div key={index}>{error}</div>);
    });
    const storeAllValidtionErr = (
      <>
       {collectAllValidationErr}
      </>
    );
    errortoast(storeAllValidtionErr);
  }

  const handleUpdatePwd = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      let res = await fetch(apibaseURl+"updatePwd", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          oldpwd: oldpwd,
          newpwd: newpwd,
          confirmnewpwd: confirmnewpwd,
         
        }),
      });
      let output = await res.json();
      setDisabled(false);
      if (res.status === 200) {
          if(output.status === 2)
          {
            getvalidationErr(output.response);
          }
          if(output.status === true)
          {
            Swal.fire({  
                    title: 'Success',  
                    text: 'Password updated successfully!',
                    icon: 'success'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setNewpwd('');
                      setConfirmnewpwd('');
                      setOldpwd('');
                    } 
                  });
          }
      }
    } catch (err) {
      setDisabled(false);
    }
  };
  
  
  const getuserdata = async () => 
  {
    const res =  await fetch(apibaseURl+"getProfileUser", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({id:id}),
          });
          let resJson = await res.json();
          return resJson;
  }

  const getUser = async () => {
    try {
      const resJson = getuserdata();
      resJson.then(function(output) {
        if(output.status === true)
        {
          setName(output.response.full_name);
          setEmail(output.response.email);
        }
    });
    } catch (err) {
      console.log(err);
    }
  };



  

  useEffect(() => {
    getUser();
  },[id])
  return (
    <>
     <ToastContainer />

     <div className="section-body">
                <div className="row mt-sm-4">
                    
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className="card">
                          <div className="padding-20">
                              <ul className="nav nav-tabs" id="myTab2" role="tablist">
                                  <li className="nav-item">
                                    <Link 
                                    className={ isActive == 'profile' ?  'nav-link  active' : 'nav-link'  } 
                                    onClick={()=> triggerTab('profile') }
                                    >Profile</Link>
                                  </li>
                                  <li className="nav-item">
                                    <Link className={ isActive == 'changepassowrd' ?  'nav-link  active' : 'nav-link'  } 
                                       onClick={()=> triggerTab('changepassowrd') }
                                       
                                       >Change Password</Link>
                                  </li>
                            </ul>

                              <div className="tab-content tab-bordered" id="myTab3Content">
                                    <div className={isActive == 'profile' ? 'tab-pane  fade show active' : 'tab-pane fade' }  id="about" role="tabpanel" aria-labelledby="home-tab2">
                                            <form onSubmit={handleSubmit}>
                                              <div className="row">
                                                  <div className="col-sm-6">
                                                    <div className="form-group">
                                                          <label>Name<small className="text-danger">*</small></label>
                                                          <input
                                                              type="text"
                                                              value={name}
                                                              placeholder="Name"
                                                              onChange={(e) => setName(e.target.value)}
                                                              className="form-control"
                                                            />
                                                      </div>
                                                  </div>
                                                
                                                 
                                                  <div className="col-sm-6">
                                                      <div className="form-group">
                                                          <label>Email</label>
                                                          <input
                                                            type="text"
                                                            value={email}
                                                            placeholder="Email"
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            className="form-control"
                                                            readOnly
                                                          />
                                                        </div>
                                                  </div>
                                                  


                                                  
                                                 

                                                  <div className="col-12">
                                                    <div className="card-footer text-center">
                                                        <button className="btn btn-primary mr-1"  type="submit" disabled={disabled}>{disabled == false ? 'Update Profile': 'Updating...'}</button>
                                                        
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                    </div>
                                  <div className={isActive == 'changepassowrd' ? 'tab-pane  fade show active' : 'tab-pane fade' }  id="settings" role="tabpanel" aria-labelledby="profile-tab2">
                                  <form onSubmit={handleUpdatePwd}>
                                  

                                              <div className="row">
                                                  <div className="col-sm-12">
                                                      <div className="form-group">
                                                          <label>Old Password<small className="text-danger">*</small></label>
                                                          <input
                                                              type="password"
                                                              value={oldpwd}
                                                              placeholder="Old Password"
                                                              onChange={(e) => setOldpwd(e.target.value)}
                                                              className="form-control"
                                                            />
                                                      </div>
                                                  </div>

                                                  <div className="col-sm-12">
                                                      <div className="form-group">
                                                          <label>New Password<small className="text-danger">*</small></label>
                                                          <input
                                                              type="password"
                                                              value={newpwd}
                                                              placeholder="New Passowrd"
                                                              onChange={(e) => setNewpwd(e.target.value)}
                                                              className="form-control"
                                                            />
                                                      </div>
                                                  </div>
                                                  

                                                  <div className="col-sm-12">
                                                      <div className="form-group">
                                                          <label>Confirm Password<small className="text-danger">*</small></label>
                                                          <input
                                                              type="password"
                                                              value={confirmnewpwd}
                                                              placeholder="Confirm Password"
                                                              onChange={(e) => setConfirmnewpwd(e.target.value)}
                                                              className="form-control"
                                                            />
                                                      </div>
                                                  </div>
                                                  
                                                  
                                                 

                                                  <div className="col-12">
                                                    <div className="card-footer text-center">
                                                        <button className="btn btn-primary mr-1"  type="submit" disabled={disabled}>{disabled == false ? 'Change Password': 'Updating...'}</button>
                                                        
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  
                  </div>
                 
                  </div>
    </>

  );
}

export default Profile;
