import React , { Suspense, lazy , useRef ,  useState , useEffect , useContext } from "react";
import { Link , NavLink, Outlet  } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import  { WebAssetsContext }  from "../../../App";

const Layout = () => {
  const Admincss = React.lazy(() => import('../../../Admincss'));
  const [minibar, setMinibar] = useState(false); 
  const minibarref = useRef(false);
  const WebAssets = useContext(WebAssetsContext);
  const size = useRef(window.innerWidth);
  const minibatToggle = (x) => {
      if(minibarref.current == false)
      {
        minibarref.current = true;
        
      }else
      {
        minibarref.current = false;
      }
      setMinibar(minibarref.current);
    };
    
    const minibarCurrent = () => 
    {
      
      if(size.current > 1024)
      {
        setMinibar(false);
      }else
      {
        setMinibar(true);
      }
    }

    useEffect(() => {
      minibarCurrent();
    }, [size.current])
  return (
    <>
      <Suspense>
       <Admincss />
        
      <div id="app" className={minibar == true ? 'sidebar-mini' : ''  }>
        <div className="main-wrapper main-wrapper-1">
            <Header minibatToggle={minibatToggle} />
            <Sidebar minibatToggle={minibatToggle} />
            
            <div className="main-content sectionminheight">
                <section className="section z-index-low">
                     <Outlet />       
                </section>
            </div>
            <Footer />
        </div>
      </div>
      </Suspense>
      
    </>
  )
};

export default Layout;