import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import check from "../../../assets/web/images/check.png";
import logo from "../../../assets/web/images/thank-logo.png";
const OrderSuccess = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setTimeout(() => {
      navigate("/orders");
    }, 5000);
  }, []);
  return (
    <>
      <section class="section order-detail-2">
        <div class="container ">
          <div class=" thank-you-bg">
            <div class="thankyou-page">
              <div class="thank-you-icon text-center mb-4">
                <img class="img-fluid" src={check} alt="" />
              </div>
              <div class="thanks-upper-heading text-center">
                <h4>YOUR ORDER IS COMPLETE</h4>
                <p>Thank you for working with Sticker Pack</p>
              </div>
              <div class="img-box thankyou-logo text-center">
                <img class="img-fluid" src={logo} alt="" />
              </div>
              <div class="thanks-upper-heading thanks-bottom-headijng text-center">
                <h4>Thank You</h4>
                <p>
                  A copy of your order has been emailed to you. If you didn’t
                  specify a purchase
                  <br /> order then we have provided one.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderSuccess;
