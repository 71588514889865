import React, { useState, useContext , useEffect } from "react";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import { WebAssetsContext } from "../../App";
import Swal from 'sweetalert2';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { alertDanger , apibaseURl , errorToast , successToast  , getvalidationErr} from "../../functions/Function";

const Useradd = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  const params = useParams();
  const [disabled, setDisabled] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState('1');



  
  const errortoast = (text) => {
    toast.error(text,
      {
        autoClose: 3000,
        transition: Zoom,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: true,
      }
    );
  };

  const actvieStatus = () => {
    setStatus('1');
  }

  const inactvieStatus = () => {
    setStatus('0');
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    try {
      let res = await fetch(apibaseURl + "addStaff", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: firstname,
          last_name: lastname,
          email: email,
          password: password,
          status: status,
        }),
      });
      let output = await res.json();
      setDisabled(false);
      if (res.status === 200) {
        if (output.status === 2) {
          getvalidationErr(output.response);
        }
        if (output.status === true) {
          setDisabled(false);
          setFirstname('');
          setLastname('');
          setPassword('');
          setStatus('');
          Swal.fire({
            title: 'Success',
            text: 'Staff user added uccessfully!',
            icon: 'success'
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/admin/staff");
            }
          });
        }

      }
    } catch (err) {
      setDisabled(false);
    }
  };

  const getvalidationErr = (errors) => {
    const errorsConvertInArry = Object.values(errors);
    let collectAllValidationErr = [];
    errorsConvertInArry.forEach((error, index) => {
      collectAllValidationErr.push(<div key={index}>{error}</div>);
    });
    const storeAllValidtionErr = (
      <>
        {collectAllValidationErr}
      </>
    );
    errortoast(storeAllValidtionErr);
  }

  useEffect(() => {
  
  }, [])

  return (
    <>
      <ToastContainer />
      <div className="section-body">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Add Staff Information</h4>
                <div className="card-header-form">
                  <button onClick={() => navigate("/admin/staff")} className="btn btn-icon icon-left btn-primary"><i className="fas fa-arrow-right"></i> Back</button>
                </div>
              </div>
              <div className="card-body p-4">

                <form onSubmit={handleSubmit} id="create-course-form">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name <small className="text-danger">*</small></label>
                        <input
                          type="text"
                          value={firstname}
                          placeholder="First Name"
                          onChange={(e) => setFirstname(e.target.value)}
                          className="form-control"
                        />

                      </div>

                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          value={lastname}
                          placeholder="Last Name"
                          onChange={(e) => setLastname(e.target.value)}
                          className="form-control"
                        />

                      </div>

                    </div>

                    


                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email<small className="text-danger">*</small></label>
                        <input
                          type="text"
                          value={email}
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password<small className="text-danger">*</small></label>
                        <input
                          type="text"
                          value={password}
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>

                 

                    
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Status</label>
                        <div className="row">
                          <div className="col-sm-3">
                            <input type="radio" id="statusActive" name="status" className=""
                              value="1"
                              checked={status === '1'}
                              onChange={actvieStatus}

                            /> <label htmlFor="statusActive">Active</label>
                          </div>
                          <div className="col-sm-3">
                            <input type="radio" id="statusInactive" name="status" className="custom-control-inpu"
                              value="0"
                              checked={status === '0'}
                              onChange={inactvieStatus}
                            /> <label htmlFor="statusInactive">Inactive</label>
                          </div>
                        </div>



                      </div>
                    </div>

                    <div className="col-12">
                      <div className="card-footer text-center">
                        <button className="btn btn-primary mr-1" type="submit" disabled={disabled}> {disabled == false ? 'Submit' : 'Submiting...'} </button>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
}

export default Useradd;
