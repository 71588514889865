import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { WebAssetsContext } from "../../App";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import Addwallet from "../../components/admin/Addwallet";
import { filebasepath } from "../../functions/Function";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import {
  alertDanger,
  apibaseURl,
  errorToast,
  successToast,
  getvalidationErr,
} from "../../functions/Function";
const Staff = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  const HandleClickOnEditUser = (id = "") => {
    navigate("/admin/origin/edit/" + id);
  };
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);
  const fetchData = () => {
    fetch(apibaseURl + "getorigins")
      .then((res) => res.json())
      .then((result) => {
        if (result.status == true) {
          setData(result.response);
          setloading(false);
          handleCallDataTable();
        }
      });
  };

  const HandleClickOnDelete = async (id = "") => {
    Swal.fire({
      title: "Are you sure you want to delete it ?",
      text: "Once deleted, you will not be able to get it again!",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const resJson = DeleteApi(id);
          resJson.then(function (output) {
            if (output.status == true) {
              Swal.fire({
                title: "Deleted!",
                text: "Staff user has been deleted successfully.",
                icon: "success",
              }).then((finalresult) => {
                if (finalresult.isConfirmed) {
                  fetchData();
                }
              });
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
    });
  };
  const DeleteApi = async (id = "") => {
    const res = await fetch(apibaseURl + "deleteOrigin", {
      method: "POST",
      body: JSON.stringify({ id: id }),
    });
    let resJson = await res.json();
    return resJson;
  };

  const handleCallDataTable = () => {
    $("#dataTable").DataTable().destroy();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(function () {
      $("#theme-datatable").DataTable();
    }, 2000);
  }, [loading]);

  return (
    <>
      <div className="section-body">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="pt-4 pl-4 pr-4">
                <div className="card-header pl-0 pr-0 w-50 float-left">
                  <h4 className="headercolor">Origins List</h4>
                </div>
                <div className="float-right">
                  <button
                    onClick={() => navigate("/admin/origin/add")}
                    className="btn btn-icon icon-left btn-primary"
                  >
                    <i className="fas fa-plus"></i> Add
                  </button>
                </div>
              </div>
              <div className="card-body pl-4 pr-4 pb-4 pt-0">
                <div className="table-responsive theme-table">
                  <table
                    className="table table-hover table-bordered display"
                    id="theme-datatable"
                  >
                    <thead>
                      <tr>
                        <th className="text-center" width="40px">
                          <div className="w-120"> S.NO</div>
                        </th>
                        <th>Title</th>
                        <th>Status</th>
                        <th className="text-right">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 &&
                        data.map((row, i) => (
                          <tr key={i}>
                            <td className="text-center highlighter font-800">
                              {i + 1}
                            </td>

                            <td className="font-800">
                              <div className="w-title-300">{row.title}</div>
                            </td>

                            <td>
                              {row.status == "1" ? (
                                <div className="badge badge-success">
                                  Active
                                </div>
                              ) : (
                                <div className="badge badge-danger">
                                  In-Active
                                </div>
                              )}
                            </td>
                            <td className="text-right">
                              <button
                                role="button"
                                id={"edit_" + row.id}
                                onClick={() => HandleClickOnEditUser(row.id)}
                                className="btn btn-icon btn-primary mr-2"
                                data-tooltip-content="Edit"
                              >
                                <i className="far fa-edit"></i>
                              </button>
                              <Tooltip anchorId={"edit_" + row.id} />

                              <button
                                role="button"
                                id={"delete_" + row.id}
                                onClick={() => HandleClickOnDelete(row.id)}
                                className="btn btn-icon btn-danger"
                                data-tooltip-content="Delete"
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                              <Tooltip anchorId={"delete_" + row.id} />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Staff;
