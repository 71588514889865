
import React, { Suspense, lazy, useRef, useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { updateSession, loaderBar, miniloaderBar , apibaseURl, currencySymbol, errorToast, successToast, loggedInCustomerDetails , getvalidationErr } from "../../functions/Function";
const  Changepassword = (props) => {

  const [disabled, setDisabled] = useState(false);
  const [inputs, setInputs]  = useState(
    {
      "user_id":loggedInCustomerDetails("id"),
      "old_password":'',
      "new_password":'',
      "confirm_password":''
    }
    )

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = async (event) => {
      event.preventDefault();
      setDisabled(true);
      try {
        let res = await fetch(apibaseURl + "updatePassword", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(inputs),
        });
        let output = await res.json();
      
        setDisabled(false);
        if (res.status === 200) {
          
          if(output.result == true)
          {
            setInputs({
              "user_id":loggedInCustomerDetails("id"),
              "old_password":'',
              "new_password":'',
              "confirm_password":''
            })
              successToast(output.msg , 'top-right' ,'6000');
           }else
          {
            if(output.validation == true)
            {
              errorToast(getvalidationErr(output.response) , 'top-right' ,'6000');
            }else
            {
              errorToast(output.msg , 'top-right' ,'6000');
           }
          }
  
        }
      } catch (err) {
        setDisabled(false);
      }
    }
  return (
    
    <>
        <ToastContainer />
        <section className="section registratio-box ">
            <div className="container">
                <div className="form-box box-shadow">
                  <form method="POST" onSubmit={handleSubmit}>
                      <h2 className="form-heading">
                        Change Password
                      </h2>
                      <div className="mb-3 form-input-box">
                        <label className="form-label">Old Password</label><span className="form-star">
                        </span>
                        <div className="form-input-box">
                           
                            <input
                                  type="password"
                                  name="old_password"
                                  value={inputs.old_password || ""}
                                  onChange={handleChange}
                                  placeholder="Old Password"
                                  className="form-control"
                              />
                            <div className="form-icon">
                              <i className="fa-solid fa-user"></i>
                            </div>
                        </div>
                      
                      </div>
                      <div className="mb-3 form-input-box">
                        <label className="form-label">New Password</label><span className="form-star">
                        </span>
                        <div className="form-input-box">
                           

                            
                            <input
                                  type="password"
                                  name="new_password"
                                  value={inputs.new_password || ""}
                                  onChange={handleChange}
                                  placeholder="New Password"
                                  className="form-control"
                              />

                            <div className="form-icon">
                              <i className="fa-solid fa-envelope"></i>
                            </div>
                        </div>
                       
                      </div>
                      <div className="mb-3 form-input-box">
                        <label className="form-label">Confirm New Password</label><span className="form-star">
                        </span>
                        <div className="form-input-box">
                           
                            <input
                                  type="password"
                                  name="confirm_password"
                                  value={inputs.confirm_password || ""}
                                  onChange={handleChange}
                                  placeholder="Confirm New Password"
                                  className="form-control"
                              />
                            <div className="form-icon">
                              <i className="fa-solid fa-envelope"></i>
                            </div>
                        </div>
                        
                      </div>
                      <div className="text-center">
                       
                            <button className="btn site-btn" type="submit" disabled={disabled}> {disabled == false ? 'Change Password' : 'Updating...'} <span><i
                                  className="fa-solid fa-chevron-right"></i></span> </button>
                      </div>
                  </form>
                </div>
            </div>
          </section>
    </>

  );
}

export default Changepassword;
