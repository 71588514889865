import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import Rangslideritem from "../../../components/web/Rangslideritem";
import { errorToast } from "../../../functions/Function";
import { ToastContainer, toast, Zoom } from "react-toastify";
const StepFive = ({
  inputs,
  setInputs,
  validation,
  setvalidation,
  handleChange,
  validateInputs,
  validtionError,
  step,
  stepvalidation,
  nextStep,
  backStep,
  validationCheckStepFive,
}) => {
  const [percentage, setpercentage] = useState(0);
  const [validationAlert, setvalidationAlert] = useState("");

  const updatepercentage = () => {
    let totalpercentage = 0;
    {
      inputs.category.map(function (object, i) {
        totalpercentage = Number(totalpercentage) + Number(object.percentage);
      });
    }
    setpercentage(totalpercentage);
  };
  const checkkeyWordsAndPercentage = () => {
    let getblankKeyowrd = true;
    let totalpercentage = 0;
    {
      inputs.category.map(function (object, i) {
        totalpercentage = Number(totalpercentage) + Number(object.percentage);
        if (object.keyword == "") {
          getblankKeyowrd = false;
        }
      });
    }
    setpercentage(totalpercentage);

    if (totalpercentage < 100 || totalpercentage > 100) {
      setvalidationAlert("The percentage total of all the rows should be 100.");
      errorToast(
        "The percentage total of all the rows should be 100.",
        "top-right",
        "5000000"
      );
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return;
    }

    if (totalpercentage == 100) {
      validationCheckStepFive();
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    updatepercentage();
  }, []);
  return (
    <>
      <ToastContainer />
      <section className="section order-detail-2">
        <div className="container">
          <div className="form-box row">
            <div className="col-lg-12  inner-display-box-checks progress-bar-box">
              <div className="row">
                <div className="col-lg-12 col-sm-12 ">
                  <div className="text-center">
                    <h5>
                      Please enter the keywords and give an approximate
                      percentage of how much of that category you would like to
                      see on the order
                    </h5>
                  </div>
                  <div className="text-right">
                    <span className="totalPercentage">
                      Total : {percentage}%
                    </span>
                  </div>
                  <div className="input-digits-box mt-4 overflow">
                    {inputs.category.map(function (data, i) {
                      return (
                        <Rangslideritem
                          key={i}
                          id={`range_${i}`}
                          data={data}
                          currentValue={data.percentage}
                          index={i}
                          inputs={inputs}
                          setInputs={setInputs}
                          percentage={percentage}
                          updatepercentage={updatepercentage}
                        />
                      );
                    })}

                    <div className="text-center">
                      <button
                        onClick={() => backStep(4)}
                        className="btn site-btn backbtn"
                      >
                        {" "}
                        <span>
                          <i className="fa-solid fa-chevron-left" disabled></i>
                        </span>{" "}
                        Back
                      </button>

                      <button
                        onClick={() => checkkeyWordsAndPercentage()}
                        className="btn site-btn"
                      >
                        Next{" "}
                        <span>
                          <i className="fa-solid fa-chevron-right" disabled></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepFive;
