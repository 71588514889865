import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { WebAssetsContext } from "../../App";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Exchangesalesperson from "./Exchangesalesperson";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { loggedInUserDetails } from "../../functions/Function";
import {
  alertDanger,
  currencySymbol,
  changeDateFotmate,
  apibaseURl,
  errorToast,
  successToast,
  getvalidationErr,
} from "../../functions/Function";
const Order = (props) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const navigate = useNavigate();
  // const HandleClickOnDeleteOrder = (id = '') => {

  // }
  const [salesPerson, setSalesPersons] = useState([]);
  const [show, setShow] = useState(false);
  const [currentsalesperson, setcurrentsalesperson] = useState("");
  const [currentOrderId, setcurrentOrderId] = useState("");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const fetchData = () => {
  //   fetch(apibaseURl + "getOrders")
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.status == true) {
  //         console.log(result.response);
  //         setData(result.response);
  //         console.log($.fn.DataTable.isDataTable("#theme-datatable"));
  //         if ($.fn.DataTable.isDataTable("#theme-datatable") == false) {
  //           console.log("Hello");
  //           setTimeout(function () {
  //             $("#theme-datatable").DataTable();
  //           }, 2000);
  //         }
  //       }
  //     });
  // };

  const fetchData = () => {
    fetch(apibaseURl + "getOrders")
      .then((res) => res.json())
      .then((result) => {
        if (result.status == true) {
          console.log(result.response);

          // Assuming setData is a function you have defined elsewhere

          // if ($.fn.DataTable.isDataTable("#theme-datatable")) {
          //   $("#theme-datatable").DataTable().destroy();
          // }
          setData(result.response);
          // Check if the DataTable is not initialized before initializing it.
          if (!$.fn.DataTable.isDataTable("#theme-datatable")) {
            console.log("Hello");
            setTimeout(function () {
              $("#theme-datatable").DataTable();
            }, 2000);
          }
        }
      });
  };

  const handleClickOnStatus = (orderid = "") => {
    Swal.fire({
      title: "Are you sure you want to change order status it ?",
      text: "Once changed order status, you will not be able to get it again!",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStatus(orderid);
      }
    });
  };

  const deleteStatus = async (orderid = "") => {
    let res = await fetch(apibaseURl + "updateOrderStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: orderid,
      }),
    });

    let output = await res.json();
    if (res.status === 200) {
      if (output.status === true) {
        successToast(output.response, "top-right", "6000");
        fetchData();
      }
    }
  };

  // delete api testing===========================================================>

  const HandleClickOnDeleteOrder = async (id = "") => {
    setLoading(true);
    Swal.fire({
      title: "Are you sure you want to delete it?",
      text: "Once deleted, you will not be able to get it again!",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const output = await Deleteorder(id);

          if (output.status === true) {
            Swal.fire({
              title: "Deleted!",
              text: "Order has been deleted successfully.",
              icon: "success",
            }).then((finalresult) => {
              if (finalresult.isConfirmed) {
                setLoading(false);
                fetchData();

                // Do not manipulate the DOM directly using jQuery $('#theme-datatable').DataTable()
              }
            });
          }
        } catch (error) {
          // Handle any errors that occur during the delete request
          console.error(error);
        }
      }
    });
  };

  const Deleteorder = async (id = "") => {
    const res = await fetch(apibaseURl + "deleteOrder", {
      method: "POST",
      body: JSON.stringify({ id: id }),
    });
    let resJson = await res.json();
    return resJson;
  };

  const handleClose = (row) => {
    setShow(false);
  };
  const handleShow = (row) => {
    setShow(true);
    setcurrentsalesperson(row.user_id);
    setcurrentOrderId(row.id);
  };

  const fetchSalesPersons = () => {
    fetch(apibaseURl + "getstaffs")
      .then((res) => res.json())
      .then((result) => {
        if (result.status == true) {
          setSalesPersons(result.response);
        }
      });
  };

  useEffect(() => {
    if (loggedInUserDetails("role") == "administrator") {
      fetchData();
      fetchSalesPersons();
    }
  }, []);

  useEffect(() => {
    // setTimeout(function () {
    //   $("#theme-datatable").DataTable();
    // }, 2000);
  }, []);

  const grandTotal = (amount = 0, discount = 0) => {
    const totalAmount = Number(amount);
    const totalDiscount = Number(discount);
    const result = (totalAmount - totalDiscount).toFixed(2);
    return result;
  };

  useEffect(() => {
    // setTimeout(function () {
    //   $("#theme-datatable").DataTable();
    // }, 2000);
  }, [data]);

  return (
    <>
      {loading == false && (
        <>
          <Exchangesalesperson
            show={show}
            handleClose={handleClose}
            salesPerson={salesPerson}
            currentsalesperson={currentsalesperson}
            currentOrderId={currentOrderId}
          />
          <ToastContainer />
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="pt-4 pl-4 pr-4">
                    <div className="card-header pl-0 pr-0 w-50 float-left">
                      <h4 className="headercolor">Orders List</h4>
                    </div>
                  </div>
                  <div className="card-body pl-4 pr-4 pb-4 pt-0">
                    <div className="table-responsive theme-table">
                      <table
                        className="table table-hover table-bordered display"
                        id="theme-datatable"
                      >
                        <thead>
                          <tr>
                            <th width={"20px"} className="text-center">
                              S.NO
                            </th>
                            <th width={"100px"}>
                              <span className="actionw-60">Order ID </span>
                            </th>
                            <th width={"100px"}>
                              <span className="actionw-60">Staff ID </span>
                            </th>
                            <th width={"100px"}>
                              <span className="actionw-180">Staff Name </span>
                            </th>
                            <th width={"300px"}>
                              <span className="actionw-180">Company Name</span>
                            </th>
                            <th width={"300px"}>
                              <span className="actionw-180">Buyer Name</span>
                            </th>
                            <th width={"300px"}>
                              <span className="actionw-180">Buyer Email</span>
                            </th>
                            <th width={"300px"}>
                              <span className="actionw-180">Buyer Phone</span>
                            </th>
                            <th width={"300px"}>
                              <span className="actionw-180">Delivery Date</span>
                            </th>
                            <th width={"200px"}>
                              <span className="actionw-180">Amount</span>
                            </th>
                            <th width={"200px"}>
                              <span className="actionw-180">Discount</span>
                            </th>
                            <th width={"200px"}>
                              <span className="actionw-180">Total Amount</span>
                            </th>

                            <th>Status</th>
                            <th className="text-right">
                              <span className="actionw-180">Action</span>
                            </th>
                          </tr>
                        </thead>
                        {data.length > 0 && (
                          <tbody>
                            {data.length > 0
                              ? data.map((row, i) => (
                                  <>
                                    <tr key={i}>
                                      <td className="text-center highlighter font-800">
                                        {i + 1}
                                      </td>
                                      <td className="highlighter font-800">
                                        #{row.id}
                                      </td>
                                      <td className="highlighter font-800">
                                        #{row.user_id}
                                      </td>
                                      <td className="font-800">
                                        {row.staff_name}
                                      </td>
                                      <td className="font-800">
                                        {row.company_name}
                                      </td>
                                      <td className="font-800">
                                        {row.buyer_name}
                                      </td>
                                      <td className="font-800">
                                        {row.buyer_email}
                                      </td>
                                      <td className="font-800">
                                        {row.buyer_phone}
                                      </td>
                                      <td className="font-800">
                                        {row.delivery_date != "0000-00-00" &&
                                          changeDateFotmate(
                                            row.delivery_date,
                                            "DD-MM-YYYY"
                                          )}{" "}
                                        {row.delivery_type == "asready" &&
                                          "As Ready"}{" "}
                                        {row.delivery_type == "asap" &&
                                          "ASAP - RUSH"}
                                      </td>
                                      <td className=" font-800 ">
                                        {currencySymbol}
                                        {row.amount}
                                      </td>
                                      <td className=" font-800 ">
                                        {currencySymbol}
                                        {row.discount}{" "}
                                        <span className="discountcolor">
                                          ({row.discount_percentage}%)
                                        </span>
                                      </td>
                                      <td className="highlighter font-800 ">
                                        {currencySymbol}
                                        {grandTotal(row.amount, row.discount)}
                                      </td>
                                      <td>
                                        {row.status == "Completed" ? (
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleClickOnStatus(row.id)
                                            }
                                            className="badge badge-success"
                                          >
                                            Completed
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="badge badge-danger"
                                            onClick={() =>
                                              handleClickOnStatus(row.id)
                                            }
                                          >
                                            Canceled
                                          </button>
                                        )}
                                      </td>
                                      <td className="text-right">
                                        <button
                                          role="button"
                                          id={"view_" + row.id}
                                          onClick={() =>
                                            navigate(
                                              "/admin/orders/order/" + row.id
                                            )
                                          }
                                          className="btn btn-icon btn-primary mr-2"
                                          data-tooltip-content="View Order Details"
                                        >
                                          <i className="far fa-eye"></i>
                                        </button>
                                        <Tooltip anchorId={"view_" + row.id} />

                                        {loggedInUserDetails("role") ==
                                          "administrator" && (
                                          <>
                                            <button
                                              role="button"
                                              id={"changeSaleperson_" + row.id}
                                              onClick={() => handleShow(row)}
                                              className="btn btn-icon btn-primary mr-2"
                                              data-tooltip-content="Exchange Sales Person"
                                            >
                                              <i className="fa-solid fa-arrow-right-arrow-left"></i>
                                            </button>
                                            <Tooltip
                                              anchorId={
                                                "changeSaleperson_" + row.id
                                              }
                                            />

                                            <button
                                              role="button"
                                              id={"delete_" + row.id}
                                              onClick={() =>
                                                HandleClickOnDeleteOrder(row.id)
                                              }
                                              className="btn btn-icon btn-danger"
                                              data-tooltip-content="Delete"
                                            >
                                              <i className="far fa-trash-alt"></i>
                                            </button>
                                            <Tooltip
                                              anchorId={"delete_" + row.id}
                                            />
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ))
                              : ""}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Order;
